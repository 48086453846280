const Images = {
  LOGO: require("./images/logo/logo.png"),
  LOGOMS: require("./images/logo/logoMS.png"),
  MSLOGO: require("./images/logo/msLogo.png"),
  TWITTER: require("./images/twitter.png"),

  profile: require("./images/profile.png"),
  profilePicture: require("./images/avatar_1.png"),
  profilePicture2: require("./images/avatar_2.png"),
  profilePicture3: require("./images/avatar_3.png"),
  check: require("./images/check.png"),
  red_cross: require("./images/red-cross.png"),
  googlePlay: require("./images/googlePlay.png"),
  appStore: require("./images/appStore.png"),
  no_profile: require("./images/no_profile.png"),
  moderator: require("./images/wrench_active.png"),
  not_moderator: require("./images/wrench_inactive.png"),
  hidden: require("./images/visibility_off.png"),
  close_message_input: require("./images/cancel_black_24dp.png"),
  send_message: require("./images/send.png"),
  reply: require("./images/reply.png"),
  responding: require("./images/responding.png"),
  click_for_message: require("./images/click_for_message.png"),
  statistic_view: require("./images/view.png"),
  statistic_user: require("./images/woman.png"),
  statistic_doctor: require("./images/statistic-doctor.png"),
  statistic_messages: require("./images/statistic-messages.png"),
  statistic_reply: require("./images/statistic-reply.png"),
  statistic_woman_check: require("./images/statistic-woman-check.png"),
  message_close: require("./images/lock.png"),
};

export default Images;
