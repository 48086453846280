import React from "react";
import OrdinacijaLogical, { OrdinacijaLogicalType } from "./OrdinacijaLogical";
import { Avatar } from "primereact/avatar";
import { getImage } from "../../infrastructure/Utils";
import Images from "../../infrastructure/Images";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import DialogAddDoctorContent from "./dialog/DialogAddDoctorContent";
import { Dialog } from "primereact/dialog";
import actions from "../../infrastructure/system/hooks/dialog-add-doctor-reducer/actions";
import UserReadDto from "../../model/user/UserReadDto";
import SkeletonPanelItem from "../skeleton/SkeletonPanelItem";
import SkeletonAccordionList from "../skeleton/SkeletonAccordionList";

export default function Ordinacija() {
  const {
    ordinacijaList,
    ordinacijaListLoading,
    doktorList,
    doktorListLoading,
    loadDoctorList,
    isActive,
    removeDoktor,
    openDialog,
    addDoctor,
    state,
    dispatch,
    query,
    searchDoctors,
  }: OrdinacijaLogicalType = OrdinacijaLogical();
  const renderFooter = () => {
    return (
      <div className="p-grid p-justify-center pt-4 pb-3">
        <Button className="close-button text-uppercase" onClick={() => dispatch({ type: actions.CLOSE_DIALOG })} label="Zatvori" icon="pi pi-times" />
      </div>
    );
  };

  return (
    <div className="ordinacija-layout">
      <div className="p-grid p-flex-row p-justify-between p-align-start">
        <div className="p-col-4">
          <div className="ordinacije-menu">
            {ordinacijaListLoading ? (
              <SkeletonAccordionList size={2} />
            ) : (
              ordinacijaList.map((ordinacija: any) => (
                <div
                  key={ordinacija.id}
                  className={`ordinacije-menu__item p-grid p-justify-between p-align-center p-4 mb-4 ${isActive === ordinacija.id && "active"}`}
                  onClick={() => loadDoctorList(ordinacija.id)}
                >
                  <div className="font-weight-bold">{ordinacija.name}</div>
                  <div className="ordinacije-menu__item--icon">
                    <i className="pi pi-chevron-right" />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="p-col-7 pt-0">
          <ScrollPanel className="ordinacije-doktor-list">
            {doktorList.length === 0 && !doktorListLoading && <div className="p-text-center">Izabrana ordinacija trenutno nema dodatih lekara.</div>}
            {doktorListLoading ? (
              <SkeletonPanelItem height="200px" />
            ) : (
              doktorList.map((doktor: UserReadDto) => (
                <div className="doktor-item p-4" key={doktor.id}>
                  <div className="p-grid p-align-center m-0">
                    <Avatar image={getImage(false, doktor.profilePicture, Images.profile)} className="avatar-size mr-3" shape="circle" />
                    <div className="member-item__name font-weight-bold">{doktor.firstname + " " + doktor.lastname}</div>
                  </div>
                  <div className="doktor-item__icon" onClick={() => removeDoktor(doktor.id)}>
                    <i className="pi pi-trash" />
                  </div>
                </div>
              ))
            )}
          </ScrollPanel>
          <div className="p-grid p-justify-center mt-4">
            <Button className="text-uppercase add-button" icon="pi pi-plus" label="Dodaj" onClick={() => openDialog()} />
          </div>
        </div>
      </div>
      <Dialog visible={state.isDialogOpen} onHide={() => dispatch({ type: actions.CLOSE_DIALOG })} contentClassName="p-0" className="w-50 dialogAddDoctor" showHeader={false} footer={renderFooter}>
        <DialogAddDoctorContent doktorOrdinacijaList={state.doctors} addDoctor={addDoctor} doctorsLoading={state.doctorsLoading} query={query} searchDoctors={searchDoctors} />
      </Dialog>
    </div>
  );
}
