import axios from "axios";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { AppContext } from "../../Store";

export default function RegistrationStatus() {
  const { token } = useParams<any>();
  const [registrationStatus, setRegistrationStatus] = useState<any>();
  const { showMessage } = useContext(AppContext);

  useEffectOnce(() => {
    axios
      .post(
        Endpoint.REGISTRATION_STATUS,
        { token: token },
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      )
      .then((response: any) => {
        setRegistrationStatus(response.data.data);
      })
      .catch((error) => {
        if (error.response) {
          const messages = error.response.data.errors
            .map((err: any) => {
              return err.message;
            })
            .join(" ");
          showMessage(MessageType.ERROR, "", messages);
        }
      });
  });

  return (
    <div className="layout-registration-status">
      <div className="p-grid p-fluid layout-login p-align-center">
        <div className="p-col-12 title-registration">{Labels.LABEL_REGISTRATION_TITLE}</div>
        <div className="p-col-12">
          {registrationStatus && registrationStatus.id && (
            <div>
              <img src={Images.check} alt="check" />
              <div>{Labels.LABEL_REGISTRATION_STATUS_SUCCESS} </div>
            </div>
          )}
          {registrationStatus && !registrationStatus.id && (
            <div>
              <img src={Images.red_cross} alt="red_cross" />
              <div>{Labels.LABEL_REGISTRATION_STATUS_FAILURE} </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
