import axios from "axios";
import { useContext, useState } from "react";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig, handleAxiosCallError } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import MessageType from "../../infrastructure/MessageType";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export interface NotificationLogicalTypeInterface {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  sendNotification: (text: string) => void;

  confirmationDialog: boolean;
  openConfirmationDialog: () => void;
  closeConfirmationDialog: () => void;
}
export default function NotificationLogical() {
  const [text, setText] = useState<string>("");
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);

  const { authData, showMessage } = useContext(AppContext);

  const openConfirmationDialog = () => {
    setConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialog(false);
  };
  const sendNotification = () => {
    const notification = {
      text: text,
    };
    axios
      .post(Endpoint.SEND_NOTIFICATION, notification, axiosConfig(authData.token))
      .then(() => {
        setText("");
        showMessage(MessageType.SUCCESS, Labels.SEND_NOTIFICATION_SUCCESS, "");
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  return {
    text,
    setText,
    sendNotification,
    confirmationDialog,
    closeConfirmationDialog,
    openConfirmationDialog,
  };
}
