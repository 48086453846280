import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import GroupedMessageReadDto from "../../model/messages/GroupedMessageRead";

interface GroupedMessageListProps {
  groupedMessageList: Array<GroupedMessageReadDto>;
}

export default function GroupedMessageList({ groupedMessageList }: GroupedMessageListProps) {
  const renderFirstnameAndLastname = (item: GroupedMessageReadDto) => {
    return item.doctorUser.firstname + " " + item.doctorUser.lastname;
  };

  return (
    <div className="p-5">
      <DataTable value={groupedMessageList} emptyMessage={Labels.TABLE_EMPTY_MESSAGE} className="groupedMessageListTable">
        <Column header={Labels.LABEL_FULLNAME} body={renderFirstnameAndLastname} />
        <Column field={"clinicType.name"} header={Labels.SPECIALIZATION} />
        <Column field={"messageNumber"} header={Labels.REPLY_COUNT} />
      </DataTable>
    </div>
  );
}
