import React from "react";
import { Route } from "react-router-dom";
import RegistrationStatus from "./components/public/RegistrationStatus";

export default function PublicContent() {
  return (
    <div>
      <Route exact path="/public/registrationComplete/:token" component={() => <RegistrationStatus />} />
    </div>
  );
}
