const apiBase = (window as any).REACT_APP_BACKEND_API_BASE;
const url = apiBase ? apiBase : "http://" + window.location.hostname + ":8080/ms";
const api = url + "/api";

const Endpoint = {
  AUTHENTICATE: url + "/web/authenticate",
  FUTURE_EVENT_LIST: api + "/event/eventList/active",
  ACTIVE_MUNICIPALITY_LIST: api + "/codetable/activeMunicipalityList",
  EVENT_SPORT_LIST: api + "/codetable/eventSportList",
  EVENT_STATUS_LIST: api + "/codetable/eventStatusList",
  EVENT_DIFFICULTY_LIST: api + "/codetable/eventDifficultyList",
  FINISHED_EVENT_LIST: api + "/event/eventList/finished",
  FINISHED_EVENT_LIST_FOR_PERIOD: api + "/user/finishedEventListForPeriod",
  LAST_FINISHED_EVENT: api + "/user/lastFinishedEvent",
  SPONSOR_ORGANIZATION_LIST: api + "/codetable/sponsorOrganizationList",
  TOS: "/uploads/ToS.pdf",
  PRIVACY_POLICY: "/uploads/PrivacyPolicy.pdf",
  INSTAGRAM: "https://www.instagram.com/",
  FACEBOOK: "https://www.facebook.com/",
  REGISTRATION_PAGE: "/registration",
  LOGIN: "/loginPage",
  REGISTRATION: url + "/register",
  KUDOS: api + "/kudos",
  EVENT_REGISTRATION: api + "/event/registration/register",
  EVENT_REGISTRATION_CANCEL: api + "/event/registration/cancel",
  EVENT_COMMENT: api + "/event/comment",
  PROGRESS: api + "/user/progress",
  STATISTICS: api + "/user/statistics",
  USER: api + "/user",
  EVENT: api + "/event",
  COMMENT: api + "/comment",
  ACTIVITY: api + "/activity",
  ARTICLE_LIST: api + "/article/articleList",
  ACTIVE_ARTICLE_AREA_LIST: api + "/codetable/activeArticleAreaList",
  ACTIVE_ARTICLE_AREA_LIST_BY_ARTICLE_CATEGORY: api + "/codetable/activeArticleAreaListByArticleCategory",
  ACTIVE_ARTICLE_CATEGORY_LIST: api + "/codetable/activeArticleCategoryList",
  ARTICLE_TYPE_LIST: api + "/codetable/articleTypeList",
  ACTIVE_QUESTION_AREA_LIST: api + "/codetable/activeQuestionAreaList",
  PARTNER_GRADOVI_MENJAJU_DIJABETES: "https://www.citieschangingdiabetes.com/",
  TWITTER_GRADOVI_MENJAJU_DIJABETES: "https://twitter.com/citiesdiabetes",
  OTVORI_PLAVI_KRUG: "https://www.otvoriplavikrug.rs",
  REGISTRATION_STATUS: url + "/register/complete",
  REGISTRATION_RESEND: url + "/register/resend",
  RESET_PASSWORD: url + "/resetPassword",
  FAQ_LIST: api + "/faq/faqList",
  RANG_LIST: api + "/statistics/rankListTotalDistanceMetersForPeriod",
  GOOGLE_PLAY: "https://play.google.com/store/apps/details?id=rs.heliant.mobile.opk",
  APP_STORE: "https://apps.apple.com/us/app/otvori-plavi-krug/id1538660838#?platform=iphone",
  USER_LIST: api + "/user/userList",
  USERS_LIST_BY_ROLE: api + "/user/userList/role",
  USERS_NOT_IN_CLINIC_LIST: api + "/user/userList/notIn/clinicType",
  REGISTRATION_DOCTOR: url + "/web/registerDoctor",
  CLINIC_LIST: api + "/clinicType/clinicTypeList",
  CLINIC_USER_LIST: api + "/user/userList/clinicType",
  MESSAGES_LIST: api + "/message/messageList",
  MESSAGES_LIST_FOR_CLINIC: api + "/message/messageList/clinicType",
  FORUM_BLOCK_ADMIN: api + "/forum/blockUserAdmin",

  USER_COUNT_REGISTER: api + "/user/userList/countRegister",

  USER_COUNT_FOR_ANOTHER_ACCOUNT: api + "/user/userList/countForAnotherAccount",
  //ARTICLE_READEN_COUNT: api + "/article/articleList/count",
  MS_CAFE_COUNT_USER: api + "/user/userList/countNickname",
  MASSEGE_COUNT_DOCTOR: api + "/message/messageList/doctor/count",
  MESSAGE_COUNT_PATIENT: api + "/message/messageList/patient/count",
  FORUM_MESSAGE_COUNT: api + "/forum/forumMessageList/count",

  GROUPED_MESSAGE_COUNT: api + "/message/messageList/doctorList/count",
  GROUPED_MSCAFE_MESSAGE_COUNT: api + "/forum/forumMessageList/discussionList/count",

  ARTICLE_LOG_LIST_COUNT: api + "/articleLog/articleLogList/count",
  GROUPED_ARTICLE_LOG_LIST_COUNT: api + "/articleLog/articleLogList/articleList/count",

  SEND_NOTIFICATION: api + "/notification/send-notification",

  VISIBILITY_RIGHTS: api + "/visibilityRight/visibilityRightList",
};

export default Endpoint;
