import React from "react";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export default function AppHeader() {
  return (
    <div className="p-grid layout-app-header p-justify-center">
      <div className="header-top p-grid">
        <div className="buttonArea p-xl-3 p-lg-3 p-md-6 p-sm-12"></div>
        <div className="row">
          <h1 className="header-text mt-2">{Labels.APPLICATION_NAME}</h1>
        </div>
        <div className="buttonArea p-xl-3 p-lg-3 p-md-6 p-sm-12">
          <img src={Images.LOGOMS} alt="#" className="header-home" />
        </div>
      </div>
    </div>
  );
}
