import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { getImage } from "../../infrastructure/Utils";
import UserReadDto from "../../model/user/UserReadDto";
import { AppContext } from "../../Store";
import { Avatar } from "primereact/avatar";
import { confirmDialog } from "primereact/confirmdialog";
import { UserRole, UserRoleType } from "../../controller/UserRole";

interface AppTopbarProps {
  currentUser: UserReadDto;
}

export default function AppTopbar(props: AppTopbarProps) {
  const { currentUser } = props;
  const { deleteAuthData } = useContext(AppContext);
  const [selectedButton, setSelectedButton] = useState<any>();
  let location = useLocation();
  const history = useHistory();
  const { isAdmin, isDoctor }: UserRoleType = UserRole();

  let menuHeaderModel = [];
  if (isAdmin) {
    menuHeaderModel.push(
      {
        label: Labels.MENU_ARTICLE_LIST,
        command: () => {
          setSelectedButton(Labels.MENU_ARTICLE_LIST);
          history.push("/");
        },
      },
      {
        label: Labels.MENU_FAQ_LIST,
        command: () => {
          setSelectedButton(Labels.MENU_FAQ_LIST);
          history.push("/faqList");
        },
      },
      {
        label: Labels.MENU_ORDINACIJA,
        command: () => {
          setSelectedButton(Labels.MENU_ORDINACIJA);
          history.push("/ordinacija");
        },
      },
      {
        label: Labels.MENU_MS_CAFE,
        command: () => {
          setSelectedButton(Labels.MENU_MS_CAFE);
          history.push("/msCafe");
        },
      },
      {
        label: Labels.MENU_LEKARI,
        command: () => {
          setSelectedButton(Labels.MENU_MS_CAFE);
          history.push("/doctorsList");
        },
      },
      {
        label: Labels.BUTTON_LOGOUT,
        command: () => {
          logOutDialog();
        },
      }
    );
  } else if (isDoctor) {
    menuHeaderModel.push(
      {
        label: Labels.MENU_ORDINACIJA,
        command: () => {
          setSelectedButton(Labels.MENU_ORDINACIJA);
          history.push("/ordinacijaLekar");
        },
      },
      {
        label: Labels.BUTTON_LOGOUT,
        command: () => {
          logOutDialog();
        },
      }
    );
  }
  const menuHeaderUserModel = [
    {
      items: [
        {
          label: currentUser.firstname + " " + currentUser.lastname,
          icon: "pi pi-user-edit",
          command: () => {
            history.push("/userProfile/" + currentUser?.id);
          },
        },
        {
          label: Labels.USER_PASSWORD_CHANGE,
          icon: "pi pi-lock",
          command: () => {
            history.push("/changePassword/" + currentUser?.id);
          },
        },
        {
          label: Labels.BUTTON_LOGOUT,
          icon: "pi pi-power-off",
          command: () => {
            logOutDialog();
          },
        },
      ],
    },
  ];
  let menuHeader: any = null;
  let menuHeaderUser: any = null;

  useEffect(() => {
    window.addEventListener("popstate", () => {
      if (window.location.pathname === "/") {
        setSelectedButton(undefined);
      }
    });
    let path = location.pathname;
    if (path.substr(location.pathname.lastIndexOf("/") + 1) === "faqList") {
      setSelectedButton(Labels.MENU_FAQ_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "") {
      setSelectedButton(Labels.MENU_ARTICLE_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "msCafe") {
      setSelectedButton(Labels.MENU_MS_CAFE);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "ordinacija") {
      setSelectedButton(Labels.MENU_ORDINACIJA);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "ordinacijaLekar") {
      setSelectedButton(Labels.MENU_ORDINACIJA);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "doctorsList") {
      setSelectedButton(Labels.MENU_LEKARI);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "statistic") {
      setSelectedButton(Labels.STATISTIC);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "notification") {
      setSelectedButton(Labels.NOTIFICATION);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "visibilityRights") {
      setSelectedButton(Labels.MENU_VISIBILITY_RIGHTS);
    } else {
      setSelectedButton("");
    }
  }, [location]);

  const logOutDialog = () => {
    confirmDialog({
      acceptLabel: Labels.BUTTON_LOGOUT,
      rejectLabel: Labels.BUTTON_CANCEL,
      message: Labels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED,
      icon: "pi pi-power-off",
      accept: () => {
        deleteAuthData();
        history.push("/");
      },
    });
  };

  return (
    <div className="layout-header">
      <div className="min-width-801">
        <div className="p-grid p-nogutter p-justify-center p-align-center p-col-align-center full-width">
          <div className="p-col-11">
            <div className="p-grid p-nogutter p-justify-center p-align-center">
              <NavLink to="/" hidden={isDoctor}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.MENU_ARTICLE_LIST ? "button-background " : ""}`}>{Labels.MENU_ARTICLE_LIST}</div>
              </NavLink>
              <NavLink to="/faqList" hidden={isDoctor}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.MENU_FAQ_LIST ? "button-background " : ""}`}>{Labels.MENU_FAQ_LIST}</div>
              </NavLink>
              <NavLink to="/ordinacija" hidden={isDoctor}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.MENU_ORDINACIJA ? "button-background " : ""}`}>{Labels.MENU_ORDINACIJA}</div>
              </NavLink>
              <NavLink to="/ordinacijaLekar" hidden={isAdmin}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.MENU_ORDINACIJA ? "button-background " : ""}`}>{Labels.MENU_ORDINACIJA}</div>
              </NavLink>
              <NavLink to="/msCafe" hidden={isDoctor}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.MENU_MS_CAFE ? "button-background " : ""}`}>{Labels.MENU_MS_CAFE}</div>
              </NavLink>
              <NavLink to="/doctorsList" hidden={isDoctor}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.MENU_LEKARI ? "button-background " : ""}`}>{Labels.MENU_LEKARI}</div>
              </NavLink>
              <NavLink to="/statistic" hidden={isDoctor}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.STATISTIC ? "button-background " : ""}`}>{Labels.STATISTIC}</div>
              </NavLink>
              <NavLink to="/notification" hidden={isDoctor}>
                <div className={`item-style item-right-margin ${selectedButton === Labels.NOTIFICATION ? "button-background " : ""}`}>{Labels.NOTIFICATION}</div>
              </NavLink>
              <NavLink to="/visibilityRights" hidden={isDoctor}>
                <div className={`item-style last-item-right-margin ${selectedButton === Labels.MENU_VISIBILITY_RIGHTS ? "button-background " : ""}`}>{Labels.MENU_VISIBILITY_RIGHTS}</div>
              </NavLink>
            </div>
          </div>
          <div className="p-col-1">
            <div className="p-grid p-dir-rev p-nogutter p-justify-center p-align-center">
              <div className=" user-profile-avatar">
                <Menu ref={(el) => (menuHeaderUser = el)} model={menuHeaderUserModel} popup={true} />
                <div
                  className="align-center"
                  onClick={(event) => {
                    menuHeaderUser.toggle(event);
                  }}
                >
                  <Avatar image={getImage(false, currentUser.profilePicture, Images.profile)} className="avatar-size" shape="circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-width-800">
        <div>
          <Menu ref={(el) => (menuHeader = el)} model={menuHeaderModel} popup={true} />
          <Button
            className="bars-icon"
            icon="pi pi-bars"
            onClick={(event) => {
              menuHeader.toggle(event);
            }}
          />
        </div>
      </div>
    </div>
  );
}
