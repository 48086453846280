import React from "react";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export default function AppFooter() {
  return (
    <div className="p-grid p-fluid p-justify-center layout-app-footer p-nogutter">
      <div className="p-xl-3 p-lg-6 p-md-6 p-md-12 p-grid p-dir-row p-align-center text-center">
        <div className="p-xl-3 p-lg-6 p-md-12 p-sm-12 text-center">
          <a>
            <img src={Images.LOGO} alt="#" className="header-home" />
          </a>
        </div>
      </div>
      <div className="top-border p-nogutter" />
      <div className="p-col-12 text-center p-nogutter">
        <span className="developed-by-text">{Labels.COPYRIGHT}</span>
        <a href="https://heliant.rs" target="_blank" rel="noopener noreferrer" className="link">
          <span className="text">{Labels.HELIANT}</span>
        </a>
      </div>
    </div>
  );
}
