import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import NotificationLogical, { NotificationLogicalTypeInterface } from "./NotificationLogical";
import { Dialog } from "primereact/dialog";
export default function Notification() {
  const { text, setText, sendNotification, confirmationDialog, closeConfirmationDialog, openConfirmationDialog }: NotificationLogicalTypeInterface = NotificationLogical();

  const rightContentsDialog = () => (
    <>
      <Button
        label={Labels.BUTTON_CANCEL}
        icon="pi pi-times"
        className="p-button-danger"
        onClick={() => {
          closeConfirmationDialog();
        }}
      />
      <Button
        label={Labels.BUTTON_CONFIRM}
        icon="pi pi-check"
        className="p-button-success"
        onClick={() => {
          sendNotification(text);
          closeConfirmationDialog();
        }}
      />
    </>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="p-grid p-fluid layout-profile  ">
      <div className="p-col-6">
        <div className="p-grid p-dir-row p-nogutter p-justify-end div-size ">
          <div className="p-col-12  ">
            <InputTextarea placeholder={Labels.NOTIFICATION_PLACEHOLDER} className="text-area" value={text} onChange={(e) => setText(e.target.value)}></InputTextarea>
          </div>
          <div className="p-col-3">
            <Button
              label={Labels.BUTTON_SEND}
              className="text-uppercase add-button border-radius-10 mt-4"
              icon="pi pi-send"
              disabled={text.replace(/\s/g, "") == ""}
              onClick={() => {
                openConfirmationDialog();
              }}
            />
          </div>
        </div>
      </div>
      <Dialog header={Labels.NOTIFICATION_DIALOG_TITLE} visible={confirmationDialog} onHide={closeConfirmationDialog} footer={dialogFooter} className="w-40">
        <div>{Labels.NOTIFICATION_DIALOG_TEXT}</div>
      </Dialog>
    </div>
  );
}
