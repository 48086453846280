import moment from "moment";
import EntityOperation from "./EnumEntityOperation";
import Labels from "./Labels_sr_Latn_RS";
import MessageType from "./MessageType";

const isPublicRoute = (route: string) => {
  return route.startsWith("/public");
};

const stringChecker = (s: string | undefined | null) => {
  return s ? s : "";
};

const axiosConfig = (token: string, params?: Object) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const isFormDisabled = (operation: string) => {
  let returnValue = true;

  if (!operation) return true;

  switch (operation) {
    case EntityOperation.CREATE:
    case EntityOperation.UPDATE:
      returnValue = false;
      break;
    case EntityOperation.READ:
    case EntityOperation.DELETE:
      returnValue = true;
      break;
    default:
      returnValue = true;
      break;
  }

  return returnValue;
};

const dateAndTime = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_TIME_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const date = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const time = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment.utc(date).local().format("HH:mm");
  } else {
    return "Lose zadat datum";
  }
};

const dateTimeBefore = (date: any, dateNow: any) => {
  const now = moment(dateNow);
  const durationAsMinutes = moment.duration(now.diff(date)).asMinutes();
  if (durationAsMinutes < 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes + 1) + " " + Labels.LABEL_MINUTES;
  } else if (durationAsMinutes < 24 * 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes / 60) + " " + Labels.LABEL_HOURS;
  } else {
    return moment(date).format(DATE_TIME_FORMAT);
  }
};

const imageExtention = (base64: string) => {
  if (base64) {
    switch (base64.charAt(0)) {
      case "/":
        return "jpg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
    }
  }
};

const getImage = (needUri: boolean, source: string, replaceImage: any) => {
  return source ? (needUri ? { uri: `data:image/${imageExtention(source)};base64,${source}` } : `data:image/${imageExtention(source)};base64,${source}`) : replaceImage;
};

const showActivityDuration = (seconds: number) => {
  return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : "";
};

const handleAxiosCallSuccess = (showMessage: Function, response: any) => {
  if (response.data.data.successes) {
    showMessage(
      MessageType.SUCCESS,
      undefined,
      response.data.data.successes.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.SUCCESS, undefined, response.toString());
  }
};

const handleAxiosCallError = (showMessage: Function, error: any) => {
  if (error?.response?.data.errors) {
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const localEmailRegexp = RegExp(
  //eslint-disable-next-line
  /([a-z0-9!#$%&'*+/=?^_`{|}~-￿-]+|"([a-z0-9!#$%&'*.(),<>\[\]:;  @+/=?^_`{|}~-￿-]|\\\\|\\\")+")(\.([a-z0-9!#$%&'*+/=?^_`{|}~-￿-]+|"([a-z0-9!#$%&'*.(),<>\[\]:;  @+/=?^_`{|}~-￿-]|\\\\|\\\")+"))*/
);
const domainEmailRegexp = RegExp(
  /([a-z-￿0-9!#$%&'*+/=?^_`{|}~]-*)*[a-z-￿0-9!#$%&'*+/=?^_`{|}~]+(\.([a-z-￿0-9!#$%&'*+/=?^_`{|}~]-*)*[a-z-￿0-9!#$%&'*+/=?^_`{|}~]+)*|\[(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))\]/
);
const validateEmail = (email: string) => {
  let indexOfDomain: number = email.lastIndexOf("@");
  if (indexOfDomain < 0) {
    return false;
  }
  let localPart: string = email.substring(0, indexOfDomain);
  let domainPart: string = email.substring(indexOfDomain + 1);
  if (!localEmailRegexp.test(localPart).valueOf()) {
    return false;
  }
  if (domainPart.endsWith(".")) {
    return false;
  }
  if (!domainEmailRegexp.test(domainPart).valueOf()) {
    return false;
  }
  return true;
};

const validateStringEmpty = (str: string | undefined) => !str || str.trim() === "";

const isSifraIncluded = (itemList: any, sifra: any) => itemList?.some((x: any) => x === sifra);

const skeletonTimeout = (setLoadingState: React.Dispatch<React.SetStateAction<boolean>>, startTime: moment.Moment, miliseconds: number = 500) => {
  if (moment(new Date()).diff(startTime) < miliseconds) {
    setTimeout(() => {
      setLoadingState(false);
    }, miliseconds);
  } else {
    setLoadingState(false);
  }
};

const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const DATE_TIME_FORMAT_FULL = "DD.MM.YYYY. HH:mm:ss";
const DATE_FORMAT = "DD.MM.YYYY.";
const DATE_FORMAT2 = "YYYY-MM-DD";
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const DATE_TIME_FORMAT_2 = "YYYY-MM-DD HH:mm:ss";
const DATE_TIME_FORMAT_3 = "YYYY-MM-DDTHH:mm:ss";

const quillColors = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
  "#2a918b",
];

const checkEmpty = (variable: any) => {
  if (variable === undefined || variable === null || variable === "") {
    return true;
  }
  return false;
};

export {
  isPublicRoute,
  getImage, //
  showActivityDuration,
  imageExtention,
  dateTimeBefore,
  stringChecker,
  isFormDisabled,
  dateAndTime,
  date,
  time,
  axiosConfig,
  CALENDAR_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT_FULL,
  DATE_FORMAT2,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_2,
  DATE_TIME_FORMAT_3,
  handleAxiosCallError,
  handleAxiosCallSuccess,
  quillColors,
  validateEmail,
  validateStringEmpty,
  isSifraIncluded,
  skeletonTimeout,
  checkEmpty,
};
