import React from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { Tooltip } from "primereact/tooltip";
import { Avatar } from "primereact/avatar";
import { getImage } from "../../infrastructure/Utils";
import Images from "../../infrastructure/Images";
import UserReadDto from "../../model/user/UserReadDto";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

interface MembersListType {
  members: Array<UserReadDto>;
  toggleModerate: (member: UserReadDto) => void;
  toggleForumAccess: (member: UserReadDto) => void;
}

export default function MembersList(props: MembersListType) {
  const { members, toggleModerate, toggleForumAccess } = props;

  return (
    <ScrollPanel className="ms-cafe--wrapper__members">
      {members.map((member: UserReadDto) => (
        <div className={`member-item p-4 ${member.moderator ? "moderator" : ""}`} key={member.id}>
          <div className="p-grid p-align-center m-0">
            <Avatar image={getImage(false, member.profilePicture, Images.profile)} className="avatar-size mr-3" shape="circle" />
            <div className="member-item__name font-weight-bold">{member.firstname + " " + member.lastname}</div>
          </div>
          <div className="member-item__icon p-grid p-justify-end p-align-center">
            <Tooltip target=".moderator-icon" />
            <Tooltip target=".non-moderator-icon" />
            {member.moderator ? (
              <img
                src={Images.moderator}
                alt="moderator-icon"
                className="moderator-icon mr-2"
                onClick={(e) => toggleModerate(member)}
                data-pr-tooltip={Labels.USER_REMOVE_MODERATOR_RIGHT}
                data-pr-position="top"
              />
            ) : (
              <img
                src={Images.not_moderator}
                alt="non-moderator-icon"
                className="non-moderator-icon mr-2"
                onClick={(e) => toggleModerate(member)}
                data-pr-tooltip={Labels.USER_ADD_MODERATOR_RIGHT}
                data-pr-position="top"
              />
            )}
            <Tooltip target=".block-icon" />
            <i
              className={`pi pi-ban block-icon ${!member.forumAccess ? "blocked" : ""}`}
              onClick={() => toggleForumAccess(member)}
              data-pr-tooltip={`${member.forumAccess ? Labels.USER_BLOCK : Labels.USER_UNBLOCK}`}
              data-pr-position="top"
            ></i>
          </div>
        </div>
      ))}
    </ScrollPanel>
  );
}
