import { useContext } from "react";
import { AppContext } from "../Store";
import EnumRola from "../infrastructure/EnumRola";
import { isSifraIncluded } from "../infrastructure/Utils";

interface UserRoleType {
  isSuperadmin: boolean;
  isAdmin: boolean;
  isUser: boolean;
  isDoctor: boolean;
}

export function UserRole(): UserRoleType {
  const { authData } = useContext(AppContext);
  const { userRoleList } = authData!.currentUser;

  const userRolaList = () => userRoleList.map((userRola: any) => userRola?.role.code);

  const isSuperadmin = isSifraIncluded(userRolaList(), EnumRola.SUPERADMIN);
  const isAdmin = isSifraIncluded(userRolaList(), EnumRola.ADMIN);
  const isUser = isSifraIncluded(userRolaList(), EnumRola.USER);
  const isDoctor = isSifraIncluded(userRolaList(), EnumRola.DOCTOR);

  return { isSuperadmin, isAdmin, isUser, isDoctor };
}

export type { UserRoleType };
