import React from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import Images from "../../../infrastructure/Images";
import MessageReadDto from "../../../model/messages/MessageReadDto";
import SkeletonAccordionList from "../../skeleton/SkeletonAccordionList";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProgressSpinner } from "primereact/progressspinner";

interface MessagesListType {
  messages: Array<MessageReadDto>;
  messagesListLoading: boolean;
  setSeen: (message: MessageReadDto) => void;
  openConversation: (message: MessageReadDto) => void;
  choosenMessage: MessageReadDto | undefined;
  fetchMoreMessages: () => void;
  allMessagesLoaded: boolean;
}

export default function MessagesList(props: MessagesListType) {
  const { messages, messagesListLoading, setSeen, openConversation, choosenMessage, fetchMoreMessages, allMessagesLoaded } = props;

  document.querySelector(".messages-list .p-scrollpanel-wrapper .p-scrollpanel-content")?.setAttribute("id", "messagesList");

  const takenUserTemplate = (message: MessageReadDto) => {
    return (
      <>
        <img src={Images.responding} alt="responding-icon" className="responding-icon" />{" "}
        {Labels.LABEL_DR + " " + message.patientUser.takenUser?.firstname + " " + message.patientUser.takenUser?.lastname + " " + Labels.LABEL_TAKEN_DOCTOR_TEXT}
      </>
    );
  };

  return (
    <ScrollPanel style={{ height: "400px" }} className="messages-list">
      {messagesListLoading ? (
        <SkeletonAccordionList size={7} />
      ) : (
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreMessages}
          hasMore={true}
          loader={!allMessagesLoaded && messages.length > 0 ? <ProgressSpinner strokeWidth="4" animationDuration="1s" className="loading-messages-spiner" /> : ""}
          scrollableTarget="messagesList"
          scrollThreshold={1}
          className="infinity-scroller"
        >
          {messages.map((message: MessageReadDto) => (
            <div
              key={message.id}
              className={`message-item ${message.id === choosenMessage?.id ? "active" : ""}`}
              onClick={() => {
                openConversation(message);
              }}
            >
              <div className="message-item__doctor font-weight-bold position-relative">
                {message.patientUser?.firstname + " " + message.patientUser?.lastname}
                {message.seen && <i className="pi pi-circle-fill read-icon" onClick={() => setSeen(message)}></i>}
              </div>
              <div className="message-item__text">
                <span className="text">{message.patientUser.taken && message.patientUser.takenUser !== null ? takenUserTemplate(message) : message.messageText}</span>
                {!message.seen && (
                  <div className="unread">
                    <img src={Images.hidden} alt="hidden-icon" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      )}
    </ScrollPanel>
  );
}
