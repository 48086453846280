import React from "react";
import { Route } from "react-router-dom";
import LoginPage from "./LoginPage";

export default function LoginContent() {
  return (
    <div>
      <Route exact path="/" component={() => <LoginPage />} />
      {/* <Route exact path="/registration" component={() => <Registration />} />
      <Route exact path="/loginPage" component={() => <LoginPage />} /> */}
    </div>
  );
}
