import React, { SetStateAction } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Images from "../../../infrastructure/Images";
import MessageReadDto from "../../../model/messages/MessageReadDto";
import { date, time } from "../../../infrastructure/Utils";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import SkeletonConversation from "../../skeleton/SkeletonConversation";
import { ProgressSpinner } from "primereact/progressspinner";
import InfiniteScroll from "react-infinite-scroll-component";

interface ConversationListType {
  conversation: Array<MessageReadDto>;
  isVisibleMessageInput: boolean;
  openMessageInput: () => void;
  closeMessageInput: () => void;
  sendMessage: (messageText: string) => void;
  sendMessageInputValue: string;
  setSendMessageInputValue: React.Dispatch<SetStateAction<any>>;
  conversationLoading: boolean;
  fetchMoreConversation: () => void;
  allConversationMessagesLoaded: boolean;
  isConversationTaken: boolean;
  latestDoctorMessage: MessageReadDto | undefined;
  onSetMessageAsClosed: (messageId: number) => void;
}

export default function ConversationList(props: ConversationListType) {
  const {
    conversation,
    isVisibleMessageInput,
    openMessageInput,
    closeMessageInput,
    sendMessage,
    sendMessageInputValue,
    setSendMessageInputValue,
    conversationLoading,
    fetchMoreConversation,
    allConversationMessagesLoaded,
    isConversationTaken,
    latestDoctorMessage,
    onSetMessageAsClosed
  } = props;

  return (
    <div className="position-relative conversation-wrapper">
      <div className="messages-list thread-list p-pt-3" id="threadList">
        {conversationLoading ? (
          <SkeletonConversation size={4} />
        ) : conversation.length === 0 ? (
          <div className="no-message-choosen">
            <img src={Images.click_for_message} alt="choose-message-icon" className="click-for-message" />
            <div className="mt-3">
              {Labels.LABEL_EMPTY_CONVERSATION_PICK_MESSAGE_1} <span className="p-text-bold">{Labels.LABEL_EMPTY_CONVERSATION_PICK_MESSAGE_2}</span> {Labels.LABEL_EMPTY_CONVERSATION_PICK_MESSAGE_3}
            </div>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={conversation.length}
            next={fetchMoreConversation}
            hasMore={true}
            loader={!allConversationMessagesLoaded && conversation.length > 0 ? <ProgressSpinner strokeWidth="4" animationDuration="1s" className="loading-messages-spiner" /> : ""}
            scrollableTarget="threadList"
            inverse={true}
            className="reverse-scroll"
          >
            {conversation?.map((item: MessageReadDto) => (
              <div key={item.id} className={`p-grid p-flex-row px-4 p-align-center ${item.doctorUser !== null ? "p-justify-end" : "p-justify-start"}`}>
                <div className="p-col-5">
                  {item.doctorUser !== null && <div className="thread-list__date font-weight-bold p-text-right">{"dr " + item.doctorUser?.firstname + " " + item.doctorUser?.lastname}</div>}
                  <div className={`thread-list__date font-weight-bold ${item.doctorUser !== null ? "p-text-right" : ""}`}>
                    {date(item.insertTimestamp.toString()) + " u " + time(item.insertTimestamp.toString())}
                  </div>
                  <div className={`thread-item p-3 ${item.doctorUser !== null ? "blue" : "green"}`}>
                    <div>{item.messageText}</div>
                  </div>
                </div>
                {conversation[0].id === latestDoctorMessage?.id && latestDoctorMessage?.id === item.id && (
                  <div className="close-message p-grid p-align-center p-ml-3">
                    <div className={`icon-wrapper ${item.closedUser !== null ? "already-closed" : ""}`} onClick={() => onSetMessageAsClosed(item.id)}>
                      <img src={Images.message_close} alt="close-message-icon" />
                    </div>
                    {item.closedUser && (
                      <div className="p-ml-3">
                        <div className="thread-list__date font-weight-bold">{"dr " + item.closedUser?.firstname + " " + item.closedUser?.lastname}</div>
                        <div className="thread-list__date font-weight-bold">{date(item.closedTimestamp.toString()) + " u " + time(item.closedTimestamp.toString())}</div>
                        <div className="thread-list__date font-weight-bold">{Labels.LABEL_CLOSED_MESSAGE_NOT_NEEDED_RESPONSE}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
      {conversation.length > 0 && !isConversationTaken && (
        <div className={`p-grid p-flex-row p-align-center p-px-0 p-mx-0 py-3 conversation-controll ${isVisibleMessageInput ? "p-justify-between p-px-5" : "p-justify-center"}`}>
          {!isVisibleMessageInput && <Button className="text-uppercase add-button" label={Labels.LABEL_TAKE_CONVERSATION} onClick={() => openMessageInput()} />}
          {isVisibleMessageInput && (
            <>
              <img src={Images.close_message_input} alt="close message input icon" onClick={closeMessageInput} />
              <InputText value={sendMessageInputValue ?? ""} onChange={(e) => setSendMessageInputValue(e.target.value)} />
              <img src={Images.send_message} alt="send message icon" onClick={() => sendMessage(sendMessageInputValue)} />
            </>
          )}
        </div>
      )}
    </div>
  );
}
