import "primeicons/primeicons.css";
import { Messages } from "primereact/messages";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova-accent/theme.css";
import React, { useContext } from "react";
import Content from "../Content";
import { AppContext } from "../Store";
import "./../layout/layout.scss";
import AppFooter from "./app/AppFooter";
import AppTopbar from "./app/AppTopbar";

export default function Home() {
  const { authData, setMessages } = useContext(AppContext);

  return (
    <div className="p-grid max-height p-justify-between">
      <div className="p-col-12">
        <AppTopbar currentUser={authData?.currentUser} />
      </div>
      <div className="p-col-12">
        <Messages
          ref={(el) => {
            setMessages(el);
          }}
        />
      </div>
      <div className="p-col-12">
        <div className="p-grid p-dir-column p-justify-center">
          <div className="p-lg-8 p-md-8 p-sm-8 p-col-12 p-col-align-center">
            <Content />
          </div>
        </div>
      </div>

      <div className="p-col-12 flex-end">
        <div className="p-col-12 p-col-nogutter">
          <AppFooter />
        </div>
      </div>
    </div>
  );
}
