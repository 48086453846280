import { Skeleton } from "primereact/skeleton";
import React from "react";

interface SkeletonPanelProps {
  height?: string;
  className?: string;
}
export default function SkeletonPanelItem(props: SkeletonPanelProps) {
  const { height, className } = props;

  return <Skeleton className={`p-2 p-panel mb-2 ${className ?? ""}`} height={height ?? "3.5rem"} />;
}
