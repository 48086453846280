import React from "react";
import { Skeleton } from "primereact/skeleton";

interface SkeletonAccordionListType {
  size?: number;
}

export default function SkeletonAccordionList(props: SkeletonAccordionListType) {
  const { size } = props;

  return (
    <>
      {
        // @ts-ignore
        [...Array(size ?? 1).keys()].map((j: number) => (
          <Skeleton key={j} height="70px" className="col-12 mt-2" />
        ))
      }
    </>
  );
}
