import { SetStateAction, useContext, useReducer, useState } from "react";
import { useEffectOnce } from "react-use";
import reducerDialogAddDoctor from "../../infrastructure/system/hooks/dialog-add-doctor-reducer/reducerDialogAddDoctor";
import initialStateDialogAddDoctor, { InitialStateDialogAddDoctorType } from "../../infrastructure/system/hooks/dialog-add-doctor-reducer/initialStateDialogAddDoctor";
import actions from "../../infrastructure/system/hooks/dialog-add-doctor-reducer/actions";
import ClinicTypeReadDto from "../../model/ordinacija/ClinicTypeReadDto";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig, handleAxiosCallError, skeletonTimeout } from "../../infrastructure/Utils";
import axios, { AxiosResponse } from "axios";
import UserReadDto from "../../model/user/UserReadDto";
import moment from "moment";
import MessageType from "../../infrastructure/MessageType";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export interface OrdinacijaLogicalType {
  ordinacijaList: Array<ClinicTypeReadDto>;
  ordinacijaListLoading: boolean;
  doktorList: Array<UserReadDto>;
  doktorListLoading: boolean;
  loadDoctorList: (ordinacijaId: number) => void;
  isActive: any;
  removeDoktor: (doktorId: number) => void;
  openDialog: () => void;
  addDoctor: (doktor: any) => void;
  state: InitialStateDialogAddDoctorType;
  dispatch: React.Dispatch<{ type: actions }>;
  query: string;
  searchDoctors: (searchString: string) => void;
}

export default function OrdinacijaLogical() {
  const { authData, showMessage } = useContext(AppContext);
  const [ordinacijaList, setOrdinacijaList] = useState<Array<ClinicTypeReadDto>>([]);
  const [ordinacijaListLoading, setOrdinacijaListLoading] = useState<boolean>(false);
  const [doktorList, setDoktorList] = useState<Array<UserReadDto>>([]);
  const [doktorListLoading, setDoktorListLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<SetStateAction<any>>(null);
  const [state, dispatch] = useReducer(reducerDialogAddDoctor, initialStateDialogAddDoctor);
  const [query, setQuery] = useState<string>("");

  useEffectOnce(() => {
    getOrdinacijaData();
  });

  const loadDoctorList = (ordinacijaId: number) => {
    setDoktorListLoading(true);
    const startTime = moment(new Date());
    axios
      .get(`${Endpoint.CLINIC_USER_LIST}/${ordinacijaId}`, axiosConfig(authData.token))
      .then((res: AxiosResponse) => {
        setDoktorList(res.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        skeletonTimeout(setDoktorListLoading, startTime);
      });
    toggle(ordinacijaId);
  };

  const getOrdinacijaData = () => {
    setOrdinacijaListLoading(true);
    const startTime = moment(new Date());
    axios
      .get(Endpoint.CLINIC_LIST, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setOrdinacijaList(response.data.data);
        setIsActive(response.data.data[0].id);
        loadDoctorList(response.data.data[0].id);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        skeletonTimeout(setOrdinacijaListLoading, startTime);
      });
  };

  const toggle = (i: number) => {
    setIsActive(i);
  };

  const removeDoktor = (doktorId: number) => {
    axios
      .delete(`${Endpoint.USER_LIST}/user/${doktorId}/clinicType/${isActive}`, axiosConfig(authData.token))
      .then(() => {
        loadDoctorList(isActive);
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_REMOVED_DOCTOR_CLINIC_SUCCESS, "");
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const openDialog = () => {
    dispatch({ type: actions.LOADING, value: true });
    axios
      .get(Endpoint.USERS_NOT_IN_CLINIC_LIST, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        dispatch({ type: actions.OPEN_DIALOG, value: response.data.data });
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        dispatch({ type: actions.LOADING, value: false });
      });
  };

  const addDoctor = (doktor: UserReadDto) => {
    axios
      .put(`${Endpoint.USER_LIST}/user/${doktor.id}/clinicType/${isActive}`, doktor, axiosConfig(authData.token))
      .then(() => {
        dispatch({ type: actions.CLOSE_DIALOG });
        loadDoctorList(isActive);
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_ADDED_DOCTOR_CLINIC_SUCCESS, "");
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const searchDoctors = (searchString: string) => {
    setQuery(searchString);
  };

  return {
    ordinacijaList,
    ordinacijaListLoading,
    doktorList,
    doktorListLoading,
    loadDoctorList,
    isActive,
    removeDoktor,
    openDialog,
    addDoctor,
    state,
    dispatch,
    query,
    searchDoctors
  };
}
