import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import DoctorsListLogical, { DoctorsListLogicalType } from "./DoctorsListLogical";
import CrudDoctor from "./CrudDoctor";
import actions from "../../infrastructure/system/hooks/dialog-doctors-reducer/actions";
import EntityOperation from "../../infrastructure/EnumEntityOperation";

export default function DoctorsList() {
  const {
    first,
    tableRows,
    doctorsList,
    onPageDoctors,
    selectedRow,
    setSelectedRow,
    selectedDoctor,
    setSelectedDoctor,
    state,
    dispatch,
    openDialog,
    onCreate,
    onUpdate,
    onDelete,
  }: DoctorsListLogicalType = DoctorsListLogical();

  const leftContentsDialog = () => (
    <>
      {state.operation === EntityOperation.CREATE && (
        <Button
          icon="pi pi-plus"
          onClick={() => {
            onCreate();
          }}
          label={Labels.BUTTON_CREATE}
        />
      )}
      {state.operation === EntityOperation.UPDATE && (
        <Button
          icon="pi pi-pencil"
          onClick={() => {
            onUpdate();
          }}
          label={Labels.BUTTON_UPDATE}
        />
      )}
      {state.operation === EntityOperation.DELETE && (
        <Button
          icon="pi pi-trash"
          onClick={() => {
            onDelete();
          }}
          label={Labels.BUTTON_DELETE}
        />
      )}
    </>
  );

  const rightContentsDialog = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => dispatch({ type: actions.CLOSE_DIALOG })} />
    </>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  const dialogTitle = (entityOperation: String) => {
    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_LEKAR_CREATE;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_LEKAR_DELETE;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_LEKAR_UPDATE;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_LEKAR_READ;
      default:
        return "";
    }
  };

  const leftContents = () => (
    <>
      <Button
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          openDialog(EntityOperation.CREATE, undefined);
        }}
        tooltip={Labels.BUTTON_CREATE}
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedDoctor}
        icon="pi pi-pencil"
        onClick={() => {
          selectedDoctor && openDialog(EntityOperation.UPDATE, selectedDoctor);
        }}
        tooltip={Labels.BUTTON_UPDATE}
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedDoctor}
        icon="pi pi-info-circle"
        onClick={() => {
          selectedDoctor && openDialog(EntityOperation.READ, selectedDoctor);
        }}
        tooltip={Labels.BUTTON_DETAILS}
        tooltipOptions={{ position: "bottom" }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <Button
        disabled={!selectedDoctor}
        icon="pi pi-trash"
        onClick={() => {
          selectedDoctor && openDialog(EntityOperation.DELETE, selectedDoctor);
        }}
        tooltip={Labels.BUTTON_DELETE}
        tooltipOptions={{ position: "bottom" }}
      />
    </>
  );

  return (
    <div className="layout-faq">
      <Panel header={Labels.MENU_LEKARI}>
        <Toolbar left={leftContents} right={rightContents} />
        <div className="datatable-responsive">
          <DataTable
            value={doctorsList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              onPageDoctors(e.rows, e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedDoctor(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="p-datatable-responsive"
          >
            <Column className="column-align-center" field={"firstname"} header={Labels.LABEL_FIRSTNAME} filter filterMatchMode="contains" />
            <Column className="column-align-center" field={"lastname"} header={Labels.LABEL_LASTNAME} filter filterMatchMode="contains" />
            <Column className="column-align-center" field={"email"} header={Labels.LABEL_EMAIL} filter filterMatchMode="contains" />
          </DataTable>
        </div>
        <Dialog header={dialogTitle(state.operation)} visible={state.isDialogOpen} onHide={() => dispatch({ type: actions.CLOSE_DIALOG })} footer={dialogFooter} className="w-50">
          <CrudDoctor operation={state.operation} doctor={state.doctor} dispatch={dispatch} />
        </Dialog>
      </Panel>
    </div>
  );
}
