import UserReadDto from "../../../../model/user/UserReadDto";
import EntityOperation from "../../../EnumEntityOperation";

export interface InitialStateDialogDoctorType {
  isDialogOpen: boolean;
  doctor: UserReadDto | undefined;
  operation: string;
}

const InitialStateDialogDoctor: InitialStateDialogDoctorType = {
  isDialogOpen: false,
  doctor: undefined,
  operation: EntityOperation.CREATE,
};

export default InitialStateDialogDoctor;
