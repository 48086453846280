import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import GroupedArticleReadDto from "../../model/article/GroupedArticleRead";
interface GroupedArticleListProps {
  groupedArticleList: Array<GroupedArticleReadDto>;
}

export default function GroupedArticleList({ groupedArticleList }: GroupedArticleListProps) {
  return (
    <div className="p-5">
      <DataTable value={groupedArticleList} emptyMessage={Labels.TABLE_EMPTY_MESSAGE} className="groupedForumMessageList">
        <Column field={"article.title"} header={Labels.ARTICLE_NAME} />
        <Column field={"size"} header={Labels.CLICK_COUNT} />
      </DataTable>
    </div>
  );
}
