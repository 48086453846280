import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import GroupedForumMessageReadDto from "../../model/messages/GroupedForumMessageRead";

interface GroupedForumMessageListProps {
  groupedForumMessageList: Array<GroupedForumMessageReadDto>;
}

export default function GroupedForumMessageList({ groupedForumMessageList }: GroupedForumMessageListProps) {
  return (
    <div className="p-5">
      <DataTable value={groupedForumMessageList} emptyMessage={Labels.TABLE_EMPTY_MESSAGE} className="groupedForumMessageList">
        <Column field={"discussion.name"} header={Labels.DISCUSSION_NAME} />
        <Column field={"forumMessageCount"} header={Labels.REPLY_COUNT} />
      </DataTable>
    </div>
  );
}
