import actions from "./actions";
import { InitialStateDialogDoctorType } from "./initialStateDialogDoctor";

export default function reducerDialogDoctor(state: InitialStateDialogDoctorType, action: any) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        doctor: action.value,
        operation: action.value_operation,
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        doctor: undefined,
        operation: "",
      };
    }
    case actions.DOCTOR_CHANGE: {
      return {
        ...state,
        doctor: { ...state.doctor, [action.fieldName]: action.value },
      };
    }
    default:
      return state;
  }
}
