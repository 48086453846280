import React from "react";
import { Avatar } from "primereact/avatar";
import { getImage } from "../../../infrastructure/Utils";
import Images from "../../../infrastructure/Images";
import UserReadDto from "../../../model/user/UserReadDto";
import SkeletonAccordionList from "../../skeleton/SkeletonAccordionList";
import { InputText } from "primereact/inputtext";

interface DialogAddDoctorContentType {
  doktorOrdinacijaList: Array<UserReadDto>;
  addDoctor: (doktor: any) => void;
  doctorsLoading: boolean;
  query: string;
  searchDoctors: (searchString: string) => void;
}

export default function DialogAddDoctorContent(props: DialogAddDoctorContentType) {
  const { doktorOrdinacijaList, addDoctor, doctorsLoading, query, searchDoctors } = props;

  return (
    <>
      <div className="p-grid justify-content-center my-3 mx-0">
        <div className="p-lg-6 p-md-12 p-sm-12 p-col-10">
          <span className="p-input-icon-left p-d-block w-100">
            <InputText className="" onChange={(e) => searchDoctors(e.target.value)} />
            <i className="pi pi-search p-icon-left" />
          </span>
        </div>
      </div>
      {doctorsLoading ? (
        <SkeletonAccordionList size={4} />
      ) : (
        doktorOrdinacijaList
          .filter((doctor: UserReadDto) => {
            if (query === "") {
              return doctor;
            } else if (doctor.firstname.toLowerCase().includes(query.toLowerCase()) || doctor.lastname.toLowerCase().includes(query.toLowerCase())) {
              return doctor;
            }
          })
          .map((doktor: UserReadDto, index: number) => (
            <div className={`p-4 p-grid p-justify-between p-align-center m-0 border-bottom ${index === 0 ? "border-top" : ""}`} key={doktor.id}>
              <div className="p-grid p-align-center m-0">
                <Avatar image={getImage(false, doktor.profilePicture, Images.profile)} className="avatar-size mr-3" shape="circle" />
                <div className="font-weight-bold">{doktor.firstname + " " + doktor.lastname}</div>
              </div>
              <div className="add-icon" onClick={() => addDoctor(doktor)}>
                <i className="pi pi-plus" />
              </div>
            </div>
          ))
      )}
    </>
  );
}
