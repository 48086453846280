import moment from "moment";
import axios from "axios";
import { useContext, useState } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig, handleAxiosCallError, skeletonTimeout } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import VisibilityRightReadDto from "../../model/visibility-rights/VisibilityRightReadDto";

export interface VisibilityRightsLogicalInterface {
  confirmationDialog: boolean;
  openConfirmationDialog: (visibilityRightName: string) => void;
  closeConfirmationDialog: () => void;
  visibilityRightData: {
    [name: string]: boolean;
  };
  confirmChoice: () => void;
  visibilityRightOpted: string;
  isLoadingDataActive: boolean;
}

export default function VisibilityRightsLogical() {
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const [visibilityRightList, setVisibilityRightList] = useState<Array<VisibilityRightReadDto>>([]);
  const [visibilityRightData, setVisibilityRightData] = useState<{ [name: string]: boolean }>({});
  const [visibilityRightOpted, setVisibilityRightOpted] = useState<string>("");
  const [isLoadingDataActive, setIsLoadingDataActive] = useState<boolean>(true);
  const { authData, showMessage } = useContext(AppContext);

  useEffectOnce(() => {
    const startTime = moment(new Date());
    axios
      .get(Endpoint.VISIBILITY_RIGHTS, axiosConfig(authData.token))
      .then((response) => {
        setVisibilityRightList(response?.data?.data);
        if (response?.data?.data) {
          let visRightsTmp = {};
          response?.data?.data.map((eachRight: VisibilityRightReadDto) => {
            return visRightsTmp = { ...visRightsTmp, [eachRight.name]: eachRight.visible };
          });
          setVisibilityRightData(visRightsTmp);
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setIsLoadingDataActive, startTime);
      });
  });

  const openConfirmationDialog = (visibilityRightName: string) => {
    setVisibilityRightOpted(visibilityRightName);
    setConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setVisibilityRightOpted("");
    setConfirmationDialog(false);
  };

  const confirmChoice = () => {
    const visibilityRightId = visibilityRightList?.find((visibilityRight: VisibilityRightReadDto) => visibilityRight.name === visibilityRightOpted)?.id;
    axios
      .put(`${Endpoint.VISIBILITY_RIGHTS}/${visibilityRightId}`, { visible: !visibilityRightData[visibilityRightOpted] }, axiosConfig(authData.token))
      .then(() => {
        setVisibilityRightData({ ...visibilityRightData, [visibilityRightOpted]: !visibilityRightData[visibilityRightOpted] });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    confirmationDialog,
    openConfirmationDialog,
    closeConfirmationDialog,
    visibilityRightData,
    confirmChoice,
    visibilityRightOpted,
    isLoadingDataActive,
  };
}
