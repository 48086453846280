import EnumBaseDto from "../EnumBaseDto";
import ClinicTypeReadDto from "../ordinacija/ClinicTypeReadDto";

export default class UserReadDto {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  nickname: string;
  dateOfBirth: string;
  gender: boolean;
  weightKilograms: number;
  heightCentimeters: number;
  profilePicture: string;
  moderator: boolean;
  forumAccess: boolean;
  userRoleList: Array<EnumBaseDto>;
  clinicType: ClinicTypeReadDto | undefined;

  constructor(
    id: number,
    email: string,
    firstname: string,
    lastname: string,
    nickname: string,
    dateOfBirth: string,
    gender: boolean,
    weightKilograms: number,
    heightCentimeters: number,
    profilePicture: string,
    moderator: boolean,
    forumAccess: boolean,
    userRoleList: Array<EnumBaseDto>,
    clinicType: ClinicTypeReadDto | undefined
  ) {
    this.id = id;
    this.email = email;
    this.firstname = firstname;
    this.lastname = lastname;
    this.nickname = nickname;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.weightKilograms = weightKilograms;
    this.heightCentimeters = heightCentimeters;
    this.profilePicture = profilePicture;
    this.moderator = moderator;
    this.forumAccess = forumAccess;
    this.userRoleList = userRoleList;
    this.clinicType = clinicType;
  }
}
