import React from "react";

export interface InfoBoxProps {
  infoText: string;
}

export default function InfoBox(props: InfoBoxProps) {
  const { infoText }: InfoBoxProps = props;

  return (
    <div className="p-4 info-text p-message p-component p-message-info">
      <div className="p-d-flex p-flex-row p-align-center">
        <i className="info-text-icon p-message-icon pi pi-info-circle"></i>
        <span>{infoText}</span>
      </div>
    </div>
  );
}
