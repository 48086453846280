import React from "react";
import { Skeleton } from "primereact/skeleton";

interface SkeletonConversationType {
  size?: number;
}

export default function SkeletonConversation(props: SkeletonConversationType) {
  const { size } = props;

  return (
    <>
      {
        // @ts-ignore
        [...Array(size ?? 1).keys()].map((j: number) => (
          <div key={j} className={`p-grid p-flex-row px-4 mb-0 ${j % 2 === 0 ? "p-justify-end" : "p-justify-start"}`}>
            <div className="p-col-6">
              <Skeleton height="70px" borderRadius="15px" shape="rectangle" />
            </div>
          </div>
        ))
      }
    </>
  );
}
