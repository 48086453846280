import { Dialog } from "primereact/dialog";
import React from "react";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import Card from "./Card";
import GroupedArticleList from "./GroupedArticleList";
import GroupedForumMessageList from "./GroupedForumMessageList";
import GroupedMessageList from "./GroupedMessageList";
import StatisticLogical, { StatisticLogicalType } from "./StatisticLogical";
import GroupedUserList from "./GroupedUserList";

export default function Statistic() {
  const {
    registerUserNumber,
    articleNumber,
    msCafeUserNumber,
    patientMessageNumber,
    doctorMessageNumber,
    msCafeMessageNumber,
    displayForumDialog,
    displayMessageDialog,
    displayArticleDialog,
    closeDialog,
    openMessageDialog,
    openMSCafeDialog,
    openArticleDialog,
    groupedMessageList,
    groupedForumMessageList,
    groupedArticleList,
    openUserDialog,
    displayUserDialog,
    accountForAnotherNumber,
  }: StatisticLogicalType = StatisticLogical();
  return (
    <>
      <div className="layout-statistic">
        <div className="p-grid">
          <Card imgSrc={Images.statistic_woman_check} textLabel={Labels.REGISTER_USERS} countValue={registerUserNumber} info={true} actionFunction={openUserDialog}></Card>
          <Card imgSrc={Images.statistic_view} textLabel={Labels.READ_ARTICLE} countValue={articleNumber} info={true} actionFunction={openArticleDialog}></Card>
          <Card imgSrc={Images.statistic_user} textLabel={Labels.MS_CAFE_USER} countValue={msCafeUserNumber}></Card>
        </div>
        <div className="p-grid">
          <Card imgSrc={Images.statistic_messages} textLabel={Labels.PATIENT_MESSAGES} countValue={patientMessageNumber}></Card>
          <Card imgSrc={Images.statistic_doctor} textLabel={Labels.DOCTOR_MESSAGES} countValue={doctorMessageNumber} info={true} actionFunction={openMessageDialog}></Card>
          <Card imgSrc={Images.statistic_reply} textLabel={Labels.MS_CAFE_MESSAGES} countValue={msCafeMessageNumber} info={true} actionFunction={openMSCafeDialog}></Card>
        </div>
        <Dialog
          header={
            displayMessageDialog ? Labels.DIALOG_PATIENT_MESSAGES : displayArticleDialog ? Labels.DIALOG_ARTICLE_MESSAGES : displayUserDialog ? Labels.REGISTERED_USERS : Labels.DIALOG_MS_CAFE_MESSAGES
          }
          visible={displayForumDialog || displayMessageDialog || displayArticleDialog || displayUserDialog}
          onHide={closeDialog}
          className="displayMessagesDialog"
        >
          {displayMessageDialog && <GroupedMessageList groupedMessageList={groupedMessageList}></GroupedMessageList>}
          {displayForumDialog && <GroupedForumMessageList groupedForumMessageList={groupedForumMessageList}></GroupedForumMessageList>}
          {displayArticleDialog && <GroupedArticleList groupedArticleList={groupedArticleList}></GroupedArticleList>}
          {displayUserDialog && <GroupedUserList countForAnotherAccount={accountForAnotherNumber} countRegisteredUser={registerUserNumber}></GroupedUserList>}
        </Dialog>
      </div>
    </>
  );
}
