import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import React, { useContext, useImperativeHandle, useState } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, isFormDisabled } from "../../infrastructure/Utils";
import FaqReadDto from "../../model/faq/FaqReadDto";
import { AppContext } from "../../Store";

interface CrudFaqProp {
  faqOperation: string;
  faq?: FaqReadDto;
  onCancel: Function;
  onCreateFaq: Function;
  onUpdateFaq: Function;
  onDeleteFaq: Function;
  dialogRef?: any;
}

export default function CrudFaq(prop: CrudFaqProp) {
  const { faqOperation, faq, onCreateFaq, onUpdateFaq, onDeleteFaq, dialogRef } = prop;

  const { authData } = useContext(AppContext);

  const [isDisabled] = useState(isFormDisabled(faqOperation));
  const [questionAreaList, setQuestionAreaList] = useState([]);

  const [faqChange, setFaqChange] = useState<FaqReadDto | undefined>(faq);

  let [messages, setMessages] = useState<any>("");

  useEffectOnce(() => {
    const requestActiveQuestionAreaList = axios.get(Endpoint.ACTIVE_QUESTION_AREA_LIST, axiosConfig(authData.token));

    if (faq) {
      axios
        .get(Endpoint.FAQ_LIST + `/${faq?.id}`, axiosConfig(authData.token))
        .then((responseFaqFull) => {
          setFaqChange(responseFaqFull.data.data);
        })
        .catch((error) => {
          showError(error.response.data.message, "");
        });
    }

    axios
      .all([requestActiveQuestionAreaList])
      .then(
        axios.spread((responseQuestionArticleAreaList) => {
          setQuestionAreaList(responseQuestionArticleAreaList.data);
        })
      )
      .catch((error) => {
        showError(error.response.data.message, "");
      });
  });

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateFaq(faqChange)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },

    onUpdate: () => {
      onUpdateFaq(faqChange)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },

    onDelete: () => {
      onDeleteFaq(faqChange?.id)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
  }));

  const showError = (summary: string, detail: string) => {
    messages.replace({
      severity: "error",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  return (
    <div className="layout-faq layout-common">
      <div className="p-col-12 padding-0">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        ></Messages>
      </div>
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-4">
            <div>{Labels.LABEL_QUESTION_AREA}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <Dropdown
              disabled={isDisabled}
              dataKey="id"
              value={faqChange && faqChange.questionArea}
              options={questionAreaList}
              onChange={(e) => {
                setFaqChange({
                  ...faqChange!,
                  questionArea: e.value,
                });
              }}
              optionLabel="name"
              filter={true}
              filterBy="name"
            />
          </div>
          <div className="p-col-4">
            <div>{Labels.LABEL_ARTICLE_TITLE}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputText
              disabled={isDisabled}
              value={faqChange && faqChange.title}
              onChange={(e: any) => {
                setFaqChange({
                  ...faqChange!,
                  title: e.target.value,
                });
              }}
            />
          </div>

          <div className="p-col-4">
            <div>{Labels.LABEL_DISPLAY_ORDER}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputNumber
              value={faqChange && faqChange.displayOrder}
              onValueChange={(e: any) => {
                setFaqChange({
                  ...faqChange!,
                  displayOrder: e.target.value,
                });
              }}
              showButtons
              buttonLayout="horizontal"
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              min={0}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
      <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12">
        <div>{Labels.LABEL_ARTICLE_BODY}</div>
      </div>
      <div className="p-grid p-align-center p-nogutter">
        <div className="p-col-12 p-fluid margin-top-bottom-5">
          <InputTextarea
            rows={20}
            cols={120}
            disabled={isDisabled}
            value={faqChange && faqChange.body}
            onChange={(e: any) => {
              setFaqChange({
                ...faqChange!,
                body: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
