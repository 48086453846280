import { useContext, useState } from "react";
import { useEffectOnce } from "react-use";
import axios, { AxiosResponse } from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import EnumRola from "../../infrastructure/EnumRola";
import { axiosConfig, handleAxiosCallError } from "../../infrastructure/Utils";
import UserReadDto from "../../model/user/UserReadDto";
import { AppContext } from "../../Store";
import MessageType from "../../infrastructure/MessageType";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export interface MsCafeListLogicalType {
  members: Array<UserReadDto>;
  membersLoading: boolean;
  query: string;
  searchMembers: (searchString: string) => void;
  toggleModerate: (member: UserReadDto) => void;
  toggleForumAccess: (member: UserReadDto) => void;
}

export default function MsCafeListLogical() {
  const { authData, setShowBlockUI, showMessage } = useContext(AppContext);
  const [members, setMembers] = useState<Array<UserReadDto>>([]);
  const [membersLoading, setMembersLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  useEffectOnce(() => {
    fetchMembers("");
  });

  const fetchMembers = (searchString: string) => {
    setShowBlockUI(true);
    setMembersLoading(true);
    axios
      .get(`${Endpoint.USERS_LIST_BY_ROLE}/${EnumRola.USER}/search`, axiosConfig(authData.token, { searchString: searchString }))
      .then((response: AxiosResponse) => {
        setMembers(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        setShowBlockUI(false);
        setMembersLoading(false);
      });
  };

  const searchMembers = (searchString: string) => {
    setQuery(searchString);
    if (searchString.length > 2) {
      fetchMembers(searchString);
    }
    if (!searchString.length) {
      fetchMembers("");
    }
  };

  const toggleModerate = (member: UserReadDto) => {
    setShowBlockUI(true);
    axios
      .put(`${Endpoint.USER_LIST}/user/${member.id}/moderator/${!member.moderator}`, member, axiosConfig(authData.token))
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USER_MODERATOR, "");
        fetchMembers(query);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const toggleForumAccess = (member: UserReadDto) => {
    setShowBlockUI(true);
    axios
      .put(`${Endpoint.FORUM_BLOCK_ADMIN}/${member.id}`, member, axiosConfig(authData.token))
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USER_FORUM_ACCESS, "");
        fetchMembers(query);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    members,
    membersLoading,
    query,
    searchMembers,
    toggleModerate,
    toggleForumAccess,
  };
}
