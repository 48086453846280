import React, { useState } from "react";
import AuthData from "./AuthData";
import UserReadDto from "./model/user/UserReadDto";

interface AppContextProps {
  authData: AuthData;
  updateAuthData: (data: string) => void;
  deleteAuthData: () => void;
  showMessage: (summery: string, detail: string, severity: string) => void;
  setMessages: (messages: any) => void;
  showBlockUI: boolean;
  setShowBlockUI: any;
}

export const AppContext = React.createContext({} as AppContextProps);

const Store = (props: any) => {
  const [authData, setAuthData] = useState(localStorage.getItem("ms-app-auth") || "");
  const [messages, setMessages] = useState<any>("");
  const [showBlockUI, setShowBlockUI] = useState(false);

  const showMessage = (severity: string, summary: string, detail: string) => {
    messages.replace({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const setBlockUI = (value: boolean) => {
    if (value) {
      setShowBlockUI(value);
    } else {
      setTimeout(() => {
        setShowBlockUI(value);
      }, 500);
    }
  };

  const updateAuthData = (data: string) => {
    localStorage.setItem("ms-app-auth", data);
    setAuthData(data);
  };

  const deleteAuthData = () => {
    setAuthData("");
    localStorage.removeItem("ms-app-auth");
  };

  return (
    <AppContext.Provider
      value={{
        authData: authData !== "" ? (JSON.parse(authData) as AuthData) : new AuthData("", new UserReadDto(0, "", "", "", "", "", false, 0, 0, "", false, false, [], undefined)),
        updateAuthData,
        deleteAuthData,
        showMessage,
        setMessages,
        showBlockUI: showBlockUI,
        setShowBlockUI: setBlockUI,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default Store;
