import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import VisibilityRightsLogical, { VisibilityRightsLogicalInterface } from "./VisibilityRightsLogical";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import AddCommentIcon from "@mui/icons-material/AddComment";
import EnumVisibilityRight from "../../infrastructure/EnumVisibilityRight";
import SkeletonPanelItem from "../skeleton/SkeletonPanelItem";

export default function VisibilityRights() {
  const { confirmationDialog, openConfirmationDialog, closeConfirmationDialog, visibilityRightData, confirmChoice, visibilityRightOpted, isLoadingDataActive }: VisibilityRightsLogicalInterface =
    VisibilityRightsLogical();

  const rightContentsDialog = () => (
    <>
      <Button
        label={Labels.BUTTON_CANCEL}
        icon="pi pi-times"
        className="p-button-danger"
        onClick={() => {
          closeConfirmationDialog();
        }}
      />
      <Button
        label={Labels.BUTTON_CONFIRM}
        icon="pi pi-check"
        className="p-button-success"
        onClick={() => {
          confirmChoice();
          closeConfirmationDialog();
        }}
      />
    </>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar right={rightContentsDialog} />
      </div>
    );
  };

  const renderIcon = (visibilityRightName: string) => {
    return visibilityRightName === EnumVisibilityRight.MSCAFE ? <LocalCafeIcon className="visibility-icon" /> : <AddCommentIcon className="visibility-icon visibility-icons-scale" />;
  };

  const getObjectValue = (obj: any, key: string) => {
    return obj[key];
  };

  return (
    <div className="visibility-rights">
      <div className="col-8">
        <div>{Labels.VISIBILITY_RIGHT_MAIN_DESCRIPTION}</div>
        {isLoadingDataActive ? (
          <>
            <SkeletonPanelItem className="mt-5 rounded-border" height="10rem" />
            <SkeletonPanelItem className="mt-5 rounded-border" height="10rem" />
          </>
        ) : (
          Object.keys(visibilityRightData).map((visibilityRightName: string, id: number) => (
            <div key={id} className="card rounded-border px-4 my-5">
              <div className="d-flex align-items-center col-12">
                <div className="col-2">
                  <InputSwitch checked={visibilityRightData[visibilityRightName]} onChange={() => openConfirmationDialog(visibilityRightName)} />
                </div>
                <div className="col-2 icon-name-container mr-4 pl-0">
                  {renderIcon(visibilityRightName)}
                  <div className="visibility-text mt-2">{getObjectValue(Labels, "VISIBILITY_RIGHT_TITLE_" + visibilityRightName.toUpperCase())}</div>
                </div>
                <div className="col-8 visibility-right-description">{getObjectValue(Labels, "VISIBILITY_RIGHT_DESCRIPTION_" + visibilityRightName.toUpperCase())}</div>
              </div>
            </div>
          ))
        )}
      </div>

      <Dialog header={Labels.VISIBILITY_RIGHT_OPTION_HEADER} visible={confirmationDialog} onHide={closeConfirmationDialog} footer={dialogFooter} className="w-40">
        <div>{visibilityRightData[visibilityRightOpted] ? Labels.VISIBILITY_RIGHT_OPTION_REMOVE : Labels.VISIBILITY_RIGHT_OPTION_APPLY}</div>
      </Dialog>
    </div>
  );
}
