import React from "react";
import { InputText } from "primereact/inputtext";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import UserReadDto from "../../model/user/UserReadDto";
import actions from "../../infrastructure/system/hooks/dialog-doctors-reducer/actions";
import EntityOperation from "../../infrastructure/EnumEntityOperation";

export interface CrudDoctorType {
  operation: string;
  doctor: UserReadDto | undefined;
  dispatch: any;
}

export default function CrudDoctor(props: CrudDoctorType) {
  const { operation, doctor, dispatch }: CrudDoctorType = props;

  return (
    <>
      <div className="p-grid p-align-center p-nogutter">
        <div className="p-col-4">
          <div>{Labels.PLACEHOLDER_FIRST_NAME_REQUIRED}</div>
        </div>
        <div className="p-col-8 p-fluid margin-top-bottom-5">
          <InputText
            name="firstname"
            value={doctor?.firstname ?? ""}
            onChange={(e) => {
              dispatch({ type: actions.DOCTOR_CHANGE, fieldName: e.target.name, value: e.target.value });
            }}
            disabled={operation === EntityOperation.READ || operation === EntityOperation.DELETE}
          />
        </div>
      </div>
      <div className="p-grid p-align-center p-nogutter">
        <div className="p-col-4">
          <div>{Labels.PLACEHOLDER_LAST_NAME_REQUIRED}</div>
        </div>
        <div className="p-col-8 p-fluid margin-top-bottom-5">
          <InputText
            name="lastname"
            value={doctor?.lastname ?? ""}
            onChange={(e) => {
              dispatch({ type: actions.DOCTOR_CHANGE, fieldName: e.target.name, value: e.target.value });
            }}
            disabled={operation === EntityOperation.READ || operation === EntityOperation.DELETE}
          />
        </div>
      </div>
      <div className="p-grid p-align-center p-nogutter">
        <div className="p-col-4">
          <div>{Labels.PLACEHOLDER_EMAIL_WITHOUT_USERNAME_REQUIRED}</div>
        </div>
        <div className="p-col-8 p-fluid margin-top-bottom-5">
          <InputText
            name="email"
            value={doctor?.email ?? ""}
            onChange={(e) => {
              dispatch({ type: actions.DOCTOR_CHANGE, fieldName: e.target.name, value: e.target.value });
            }}
            disabled={operation !== EntityOperation.CREATE}
          />
        </div>
      </div>
    </>
  );
}
