import { useContext, useReducer, useState } from "react";
import { useEffectOnce } from "react-use";
import reducerDialogDoctor from "../../infrastructure/system/hooks/dialog-doctors-reducer/reducerDialogDoctor";
import initialStateDialogDoctor, { InitialStateDialogDoctorType } from "../../infrastructure/system/hooks/dialog-doctors-reducer/initialStateDialogDoctor";
import actions from "../../infrastructure/system/hooks/dialog-doctors-reducer/actions";
import Endpoint from "../../infrastructure/Endpoint";
import axios, { AxiosResponse } from "axios";
import EnumRola from "../../infrastructure/EnumRola";
import { axiosConfig, handleAxiosCallError, validateEmail, validateStringEmpty } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import UserReadDto from "../../model/user/UserReadDto";
import MessageType from "../../infrastructure/MessageType";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export interface DoctorsListLogicalType {
  first: number;
  tableRows: number;
  doctorsList: Array<UserReadDto>;
  onPageDoctors: (rows: number, first: number) => void;
  selectedRow: any;
  setSelectedRow: React.Dispatch<React.SetStateAction<any>>;
  selectedDoctor: UserReadDto | undefined;
  setSelectedDoctor: React.Dispatch<React.SetStateAction<any>>;
  state: InitialStateDialogDoctorType;
  dispatch: React.Dispatch<{ type: actions }>;
  openDialog: (operation: string, doctor: UserReadDto | undefined) => void;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
}

export default function DoctorsListLogical() {
  const { authData, setShowBlockUI, showMessage } = useContext(AppContext);
  const [tableRows, setTableRows] = useState<number>(10);
  const [first, setFirst] = useState<number>(0);
  const [doctorsList, setDoctorsList] = useState<Array<UserReadDto>>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<UserReadDto>();
  const [state, dispatch] = useReducer(reducerDialogDoctor, initialStateDialogDoctor);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setShowBlockUI(true);
    axios
      .get(Endpoint.USERS_LIST_BY_ROLE + "/" + EnumRola.DOCTOR, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setDoctorsList(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onPageDoctors = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openDialog = (operation: string, doctor: UserReadDto | undefined) => {
    dispatch({ type: actions.OPEN_DIALOG, value: doctor, value_operation: operation });
  };

  const validateAddDoctorForm = () => {
    const email = state.doctor?.email ?? "";
    if (!validateEmail(email)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_EMAIL_NOT_VALID, "");
      return false;
    }
    if (validateStringEmpty(state.doctor?.firstname)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_FIRSTNAME_FIELD, "");
      return false;
    }
    if (validateStringEmpty(state.doctor?.lastname)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_LASTNAME_FIELD, "");
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateAddDoctorForm()) {
      return;
    }

    setShowBlockUI(true);
    axios
      .post(Endpoint.REGISTRATION_DOCTOR, state.doctor, axiosConfig(authData.token))
      .then(() => {
        dispatch({ type: actions.CLOSE_DIALOG });
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_DOCTOR_SUCCESS, "");
        fetchData();
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onUpdate = () => {
    if (!validateAddDoctorForm()) {
      return;
    }

    setShowBlockUI(true);
    axios
      .put(`${Endpoint.USER_LIST}/${state.doctor.id}`, state.doctor, axiosConfig(authData.token))
      .then(() => {
        dispatch({ type: actions.CLOSE_DIALOG });
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_UPDATE_DOCTOR_SUCCESS, "");
        fetchData();
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onDelete = () => {
    setShowBlockUI(true);
    if (state.doctor.id) {
      axios
        .delete(`${Endpoint.USER_LIST}/${state.doctor.id}`, axiosConfig(authData.token))
        .then(() => {
          dispatch({ type: actions.CLOSE_DIALOG });
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_DELETE_DOCTOR_SUCCESS, "");
          fetchData();
        })
        .catch((e) => {
          handleAxiosCallError(showMessage, e);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    } else {
      setShowBlockUI(false);
    }
  };

  return {
    first,
    tableRows,
    doctorsList,
    onPageDoctors,
    selectedRow,
    setSelectedRow,
    selectedDoctor,
    setSelectedDoctor,
    state,
    dispatch,
    openDialog,
    onCreate,
    onDelete,
    onUpdate,
  };
}
