import React from "react";
import { Redirect, Route } from "react-router-dom";
import CrudArticleList from "./components/article/CrudArticleList";
import ChangePassword from "./components/changePassword/ChangePassword";
import CrudFaqList from "./components/faq/CrudFaqList";
import UserProfile from "./components/userProfile/UserProfile";
import MsCafeList from "./components/msCafe/MsCafeList";
import Ordinacija from "./components/ordinacija/Ordinacija";
import OrdinacijaLekar from "./components/ordinacija/OrdinacijaLekar";
import DoctorsList from "./components/doctors/DoctorsList";
import { UserRole, UserRoleType } from "./controller/UserRole";
import Statistic from "./components/statistic/Statistic";
import Notification from "./components/notification/Notification";
import VisibilityRights from "./components/visibility-rights/VisibilityRights";

export default function Content() {
  const { isDoctor }: UserRoleType = UserRole();
  return (
    <div>
      <Route exact path="/" component={CrudArticleList} />
      {isDoctor && <Redirect exact from="/" to="/ordinacijaLekar" />}
      <Route path="/faqList" component={CrudFaqList} />
      <Route path="/msCafe" component={MsCafeList} />
      <Route path="/ordinacija" component={Ordinacija} />
      <Route path="/ordinacijaLekar" component={OrdinacijaLekar} />
      <Route exact path="/userProfile/:id" component={UserProfile} />
      <Route exact path="/changePassword/:id" component={ChangePassword} />
      <Route exact path="/doctorsList" component={DoctorsList} />
      <Route exact path="/statistic" component={Statistic} />
      <Route exact path="/notification" component={Notification} />
      <Route exact path="/visibilityRights" component={VisibilityRights} />
    </div>
  );
}
