import React from "react";

interface CardProps {
  actionFunction?: () => void;
  imgSrc: string;
  textLabel: string;
  countValue: number;
  info?: boolean;
}

export default function Card({ countValue, imgSrc, textLabel, actionFunction, info }: CardProps) {
  return (
    <div className="col-12 col-md-6 col-lg-4" onClick={actionFunction}>
      <div className="report-card">
        <div className={"card border-round-3xl mt-8  card-info"}>
          <div className="image-wrapper">
            <img src={imgSrc} className="img" />
          </div>
          <div className="mx-auto mt-3 ">
            <div className="count text-center">
              <span className="count-value">{countValue}</span>
              {info && <i className="info-text-icon p-message-icon pi pi-info-circle"></i>}
            </div>
            <div className="action mt-3 text-center ">{textLabel}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
