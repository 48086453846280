import UserReadDto from "../../../../model/user/UserReadDto";

export interface InitialStateDialogAddDoctorType {
  isDialogOpen: boolean;
  doctors: Array<UserReadDto>;
  doctorsLoading: boolean;
}

const InitialStateDialogAddDoctor: InitialStateDialogAddDoctorType = {
  isDialogOpen: false,
  doctors: [],
  doctorsLoading: false,
};

export default InitialStateDialogAddDoctor;
