import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, DATE_TIME_FORMAT_FULL, handleAxiosCallError } from "../../infrastructure/Utils";
import ArticleCreateDto from "../../model/article/ArticleCreateDto";
import ArticleReadDto from "../../model/article/ArticleReadDto";
import ArticleUpdateDto from "../../model/article/ArticleUpdateDto";
import { AppContext } from "../../Store";
import CrudArticle from "./CrudArticle";
import { UserRole, UserRoleType } from "../../controller/UserRole";

export default function CrudArticleList() {
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);

  const [articleList, setArticleList] = useState<Array<ArticleReadDto>>();
  const [selectedRow, setSelectedRow] = useState<any>([]);

  const [entityOperation, setEntityOperation] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [articleToChange, setArticleToChange] = useState<ArticleReadDto>();
  const [selectedArticle, setSelectedArticle] = useState<ArticleReadDto | null>();
  const [tableRows, setTableRows] = useState(10);
  const [first, setFirst] = useState(0);

  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const primaryDataIndex: number = 0;
  const contentIndex: number = 1;
  const { isDoctor }: UserRoleType = UserRole();

  useEffectOnce(() => {
    if (!isDoctor) {
      fetchData();
    }
  });

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          icon="pi pi-plus"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
          label={Labels.BUTTON_CREATE}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (index === primaryDataIndex || index === contentIndex) && (
        <Button
          icon="pi pi-pencil"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
          label={Labels.BUTTON_UPDATE}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          icon="pi pi-trash"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
          label={Labels.BUTTON_DELETE}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => onCancel()} />
    </React.Fragment>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  const fetchData = (idSelectedArticle?: number) => {
    setShowBlockUI(true);
    const requestArticleList = axios.get(Endpoint.ARTICLE_LIST, axiosConfig(authData?.token, { size: 50000, page: 0 }));
    axios
      .all([requestArticleList])
      .then(
        axios.spread((responseArticleList: any) => {
          setArticleList(responseArticleList.data.data);
          setShowBlockUI(false);
          if (idSelectedArticle) {
            responseArticleList.data.data.forEach((article: any) => {
              if (idSelectedArticle === article.id) {
                setSelectedRow(article);
                setSelectedArticle(article);
              }
            });
          }
        })
      )
      .catch((e) => {
        console.log(e);
        handleAxiosCallError(showMessage, e);
        setShowBlockUI(false);
      });
  };

  const createArticle = (article: ArticleCreateDto) => {
    const createdArticle = article !== undefined ? article : {};
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${Endpoint.ARTICLE_LIST}`, createdArticle, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_ARTICLE_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
          setShowBlockUI(false);
          setIndex(0);
        })
        .catch((error: any) => {
          setIndex(0);
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const updateArticle = (article: ArticleUpdateDto) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`${Endpoint.ARTICLE_LIST}/${article.id}`, article, axiosConfig(authData.token))
        .then((response) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_UPDATE_ARTICLE_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
          setShowBlockUI(false);
          setIndex(0);
        })
        .catch((error: any) => {
          setIndex(0);
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const deleteArticle = (articleID: number) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(`${Endpoint.ARTICLE_LIST}/${articleID}`, axiosConfig(authData.token))
        .then(() => {
          setDisplayDialog(false);
          setSelectedArticle(null);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_DELETE_ARTICLE_SUCCESS, "");
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  function openDialog(entityOperation: String, articleToChange?: ArticleReadDto) {
    let u = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        u = articleToChange;
        break;
    }

    setEntityOperation(entityOperation);
    setArticleToChange(u);
    setDisplayDialog(true);
  }

  function closeDialog() {
    setIndex(0);
    setDisplayDialog(false);
  }

  function onCancel() {
    closeDialog();
  }

  const articleAreaNameBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_AREA}</span>
        {rowData.articleArea.name}
      </React.Fragment>
    );
  };
  const articleAreaTypeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_TYPE}</span>
        {rowData.articleType}
      </React.Fragment>
    );
  };
  const titleBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_TITLE}</span>
        {rowData.title}
      </React.Fragment>
    );
  };

  const displayOrderBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_DISPLAY_ORDER}</span>
        {rowData.displayOrder}
      </React.Fragment>
    );
  };

  const insertTimestampBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_INSERT_TIMESTAMP}</span>
        {rowData && rowData.insertTimestamp ? moment(rowData.insertTimestamp).format(DATE_TIME_FORMAT_FULL) : Labels.SLASH}
      </React.Fragment>
    );
  };

  const dialogHeader = (entityOperation: String) => {
    const display = articleToChange ? articleToChange.title : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_CREATE_ARTICLE;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_DELETE_ARTICLE + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_UPDATE_ARTICLE + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_READ_ARTICLE + display;
      default:
        return "";
    }
  };

  const leftContents = () => (
    <React.Fragment>
      <Button
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
        tooltip={Labels.BUTTON_CREATE}
        tooltipOptions={{ position: "bottom" }}
      />

      <Button
        className="toolbar-button"
        disabled={!selectedArticle}
        icon="pi pi-pencil"
        onClick={() => {
          selectedArticle && openDialog(EntityOperation.UPDATE, selectedArticle);
        }}
        tooltip={Labels.BUTTON_UPDATE}
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedArticle}
        icon="pi pi-info-circle"
        onClick={() => {
          selectedArticle && openDialog(EntityOperation.READ, selectedArticle);
        }}
        tooltip={Labels.BUTTON_DETAILS}
        tooltipOptions={{ position: "bottom" }}
      />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button
        disabled={!selectedArticle}
        icon="pi pi-trash"
        onClick={() => {
          selectedArticle && openDialog(EntityOperation.DELETE, selectedArticle);
        }}
        tooltip={Labels.BUTTON_DELETE}
        tooltipOptions={{ position: "bottom" }}
      />
    </React.Fragment>
  );

  return (
    <div className="layout-article">
      <Panel header={Labels.MENU_ARTICLE_LIST}>
        <Toolbar left={leftContents} right={rightContents} />
        <div className="datatable-responsive">
          <DataTable
            value={articleList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              setTableRows(e.rows);
              setFirst(e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedArticle(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="p-datatable-responsive"
          >
            <Column className="column-align-center" field={"articleArea.name"} header={Labels.LABEL_ARTICLE_AREA} filter filterMatchMode="contains" body={articleAreaNameBodyTemplate} />
            <Column className="column-align-center" field={"articleType"} header={Labels.LABEL_ARTICLE_TYPE} filter filterMatchMode="contains" body={articleAreaTypeBodyTemplate} />
            <Column className="column-align-center" field={"title"} header={Labels.LABEL_ARTICLE_TITLE} filter filterMatchMode="contains" body={titleBodyTemplate} />
            <Column className="column-align-center" field={"displayOrder"} header={Labels.LABEL_DISPLAY_ORDER} filter filterMatchMode="contains" sortable body={displayOrderBodyTemplate} />
            <Column className="column-align-center" field={"insertTimestamp"} header={Labels.LABEL_ARTICLE_INSERT_TIMESTAMP} sortable body={insertTimestampBodyTemplate} />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
          <CrudArticle
            onCreateArticle={createArticle}
            onUpdateArticle={updateArticle}
            onDeleteArticle={deleteArticle}
            articleOperation={entityOperation}
            article={articleToChange}
            onCancel={onCancel}
            dialogRef={dialogRef}
            setIndex={setIndex}
            index={index}
          />
        </Dialog>
      </Panel>
    </div>
  );
}
