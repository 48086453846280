import React, { useContext } from "react";
import MessagesList from "./messages/MessagesList";
import ConversationList from "./messages/ConversationList";
import OrdinacijaLekarLogical, { OrdinacijaLekarLogicalType } from "./OrdinacijaLekarLogical";
import { AppContext } from "../../Store";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import InfoBox from "../info-box/InfoBox";

export default function OrdinacijaLekar() {
  const { authData } = useContext(AppContext);
  const {
    messagesList,
    messagesListLoading,
    setSeen,
    conversation,
    openConversation,
    isVisibleMessageInput,
    openMessageInput,
    closeMessageInput,
    sendMessage,
    sendMessageInputValue,
    setSendMessageInputValue,
    choosenMessage,
    conversationLoading,
    getConversationMessages,
    fetchMoreMessages,
    allMessagesLoaded,
    fetchMoreConversation,
    allConversationMessagesLoaded,
    isConversationTaken,
    latestDoctorMessage,
    onSetMessageAsClosed,
    getMessages
  }: OrdinacijaLekarLogicalType = OrdinacijaLekarLogical();

  return authData.currentUser.clinicType === null ? (
    <InfoBox infoText={Labels.LABEL_DOCTOR_WITHOUT_DOCTORS_OFFICE} />
  ) : (
    <section className="ordinacija-layout">
      <div className="p-grid p-flex-row p-align-center">
        <div className="p-col-12">
          <div className="ordinacija-title text-center py-5 position-relative">
            <h3 className="font-weight-bold mb-0">{authData.currentUser.clinicType?.name}</h3>
            <i
              className="pi pi-refresh refetch-icon"
              onClick={() => {
                getConversationMessages(choosenMessage);
                getMessages();
              }}
            ></i>
          </div>
        </div>
      </div>
      <div className="p-grid p-flex-row">
        <div className="p-col-5 pt-0 pr-0">
          <MessagesList
            messages={messagesList}
            messagesListLoading={messagesListLoading}
            setSeen={setSeen}
            openConversation={openConversation}
            choosenMessage={choosenMessage}
            fetchMoreMessages={fetchMoreMessages}
            allMessagesLoaded={allMessagesLoaded}
          />
        </div>
        <div className="p-col-7 pt-0 pl-0">
          <ConversationList
            conversation={conversation}
            isVisibleMessageInput={isVisibleMessageInput}
            openMessageInput={openMessageInput}
            closeMessageInput={closeMessageInput}
            sendMessage={sendMessage}
            sendMessageInputValue={sendMessageInputValue}
            setSendMessageInputValue={setSendMessageInputValue}
            conversationLoading={conversationLoading}
            fetchMoreConversation={fetchMoreConversation}
            allConversationMessagesLoaded={allConversationMessagesLoaded}
            isConversationTaken={isConversationTaken}
            latestDoctorMessage={latestDoctorMessage}
            onSetMessageAsClosed={onSetMessageAsClosed}
          />
        </div>
      </div>
    </section>
  );
}
