const Labels = {
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  DASH: " - ",
  SLASH: "/",
  // Special strings
  SPECIAL_CHAR_REQUIRED: " *",

  // Application
  APPLICATION_NAME: "MULTIPLA SKLEROZA",

  // Common
  PRIVACY: "Privatnost",
  TERMS_OF_SERVICE: "Uslovi korišćenja aplikacije",
  PRIVACY_POLICY: "Politika privatnosti",
  FOLLOW_US: "Pratite nas",
  COPYRIGHT: "Developed by   ",
  HELIANT: " heliant",
  INSTAGRAM: "Instagram",
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  MEMBERSHIP: "Članstvo",
  REGISTRATION: "Registracija",
  LOGIN: "Prijava",
  FORGOTTEN_PASSWORD: "Zaboravljena lozinka?",
  CONTACT: "Kontakt",
  CONTACT_EMAIL: "info@otvoriplavikrug.rs",

  // Title labels
  TITLE_USER_DETAILS: "Podaci o korisniku",
  TITLE_REGISTRATION_USER: "Registracija korisnika",
  TITLE_LOGIN: "Unesite Vaše pristupne parametre",
  TITLE_WELCOME: "Dobrodošli",
  TITLE_EVENT_CREATE: "Nova aktivnost",
  TITLE_EVENT_UPDATE: "Aktivnost",
  TITLE_FINISHED_EVENTS: "Završene aktivnosti",
  TITLE_FUTURE_EVENTS: "Predstojeće aktivnosti",
  TITLE_USER: "Korisnik",
  TITLE_SPONSORS: "Prijatelji projekta",
  TITLE_STATISTICS: "Statistika",
  TITLE_PROGRESS: "Napredak",
  TITLE_ACTIVITIES: "Aktivnosti",
  TITLE_ACTION_TIME: "VREME JE ZA AKCIJU!",

  // Menu labels
  MENU_DASHBOARD: "Novosti",
  MENU_EXPLORE: "Istraži",
  MENU_PROGRESS: "Napredak",
  MENU_EVENT_LIST: "Aktivnosti",
  MENU_ARTICLE_LIST: "Članci",
  MENU_FAQ_LIST: "Česta pitanja",
  MENU_RANK_LIST: "Rang lista",
  MENU_TEST: "Test",
  MENU_MS_CAFE: "MS Cafe",
  MENU_ORDINACIJA: "Ordinacija",
  MENU_LEKARI: "Lekari",
  MENU_VISIBILITY_RIGHTS: "Funkcionalnosti",

  // Button labels
  BUTTON_CREATE: "Kreiraj",
  BUTTON_UPDATE: "Izmeni",
  BUTTON_DETAILS: "Detalji",
  BUTTON_REGISTRACIJA_EMAIL: "Registracija putem e-mail adrese",
  BUTTON_ALREADY_MEMBER: "Već ste član? Ulogujte se",
  BUTTON_LOGIN: "Prijava",
  BUTTON_LOGOUT: "Odjava",
  BUTTON_SAVE: "Sačuvaj",
  BUTTON_SAVE_AND_NOTIFY: "Sačuvaj i pošalji obaveštenje",
  BUTTON_CANCEL: "Odustani",
  BUTTON_DELETE: "Obriši",
  BUTTON_REGISTRATION: "Prihvatam i registracija",
  BUTTON_RESEND: "Pošalji opet email",
  BUTTON_USE_POINTS: "Iskoristi poene",
  BUTTON_PREVIEW: "Pregled",
  BUTTON_UPLOAD_IMAGE: "Učitaj sliku",
  BUTTON_BAN_USER: "Banuj",
  BUTTON_ADD_USER: "Dodaj lekara",
  BUTTON_SEND: "Pošalji",
  BUTTON_CONFIRM: "Potvrdi",

  //Worning labels
  WORNING_PASSWORD: "Lozinka mora sadržati najmanje 8 alfa-numeričkih karaktera",

  // Placeholder labels
  PLACEHOLDER_FIRST_NAME_REQUIRED: "Ime *",
  PLACEHOLDER_LAST_NAME_REQUIRED: "Prezime *",
  PLACEHOLDER_CHOSEN_NAME_REQUIRED: "Izabrano ime *",
  PLACEHOLDER_USERNAME: "Korisničko ime",
  PLACEHOLDER_PASSWORD: "Lozinka",
  PLACEHOLDER_EMAIL_REQUIRED: "Email (korisničko ime) *",
  PLACEHOLDER_PASSWORD_REQUIRED: "Lozinka *",
  PLACEHOLDER_PASSWORD_CONFIRM_REQUIRED: "Potvrda lozinke *",
  PLACEHOLDER_BUTTON_LOAD_MORE: "Učitaj još",
  PLACEHOLDER_POST_COMMENT: "Dodaj komentar",
  PLACEHOLDER_CHOOSE_UPLOAD: "Odaberite fotografiju",
  PLACEHOLDER_EMAIL_WITHOUT_USERNAME_REQUIRED: "Email *",

  //MULTISELECT_PLACEHOLDERS
  PLACEHOLDER_MUNICIPALITY: "Izabrano je {0} opštine",

  // CheckBox labels
  CHECKBOX_REMEMBER_PASSWORD: "zapamti lozinku",

  // Colors
  COLOR_CRIMSON: "Crimson",
  COLOR_DODGER_BLUE: "DodgerBlue",

  // Labels
  LABEL_ILI: "ili",
  LABEL_KM: "km",
  LABEL_POSLEDNJA_AKTIVNOST: "Poslednja završena aktivnost",

  LABEL_MUNICIPALITY: "Opština",
  LABEL_EVENT_TYPE: "Tip događaja",
  LABEL_EVENT_STATUS: "Status događaja",
  LABEL_START_TIME: "Vreme početka",
  LABEL_START_TOLERANCE_MINUTES: "Dozvoljeno kašnjenje [min]",
  LABEL_LENGTH_KM: "Dužina [km]",
  LABEL_LENGTH_M: "Dužina [m]",
  LABEL_REWARD_POINTS: "Nagradnih poena",
  LABEL_DESCRIPTION: "Opis",
  LABEL_NOTE: "Napomena",
  LABEL_GPS_LOCATION_START: "GPS start marker",
  LABEL_GPS_LOCATION_POINTS: "GPS route markers ",
  LABEL_GPS_LOCATION_END: "GPS end marker",
  LABEL_EMAIL: "Email",
  LABEL_FIRSTNAME: "Ime",
  LABEL_LASTNAME: "Prezime",
  LABEL_FULLNAME: "Ime i Prezime",
  LABEL_CHOSEN_NAME: "Izabrano ime",
  LABEL_DATE_OF_BIRTH: "Datum rođenja",
  LABEL_PROFILE_PICTURE: "Profilna slika",
  LABEL_AVATAR1: "Avatar1",
  LABEL_AVATAR2: "Avatar2",
  LABEL_AVATAR3: "Avatar3",
  LABEL_USED_POINTS: "Potrošeni poeni:",
  LABEL_CURRENT_POINTS: "Preostali poeni:",
  LABEL_EARNED_POINTS: "Osvojeni poeni:",
  LABEL_ADMIN: "Admin",
  LABEL_NEEDED_POINTS: "Potreban broj poena:",
  LABEL_REGISTRATION_ACCEPT: "Registracijom prihvatate ",
  LABEL_READ_HERE: "Ovde možete pročitati ",
  LABEL_DELETE: "Obriši",
  LABEL_POST: "Postavi",
  LABEL_BEFORE: "pre",
  LABEL_MINUTES: "minuta",
  LABEL_HOURS: "časova",
  LABEL_VIEW_COMMENTS: "Vidi sve komentare",
  LABEL_EDIT: "Izmeni",
  LABEL_WEEKLY_ACTIVITY: "NEDELJNE AKTIVNOSTI",
  LABEL_YEAR_TO_DATE: "GODIŠNJE AKTIVNOSTI",
  LABEL_ALL_TIME: "UKUPNO",
  LABEL_RUNS: "Događaji",
  LABEL_DISTANCE: "Distanca",
  LABEL_WALKING: "Šetnja",
  LABEL_FAST_WALKING: "Brzo hodanje",
  LABEL_RUNNING: "Trčanje",
  LABEL_EVENT_MAP: "Mapa događaja",
  LABEL_CRUD_EVENT_DATA: "Osnovni podaci o događaju",
  LABEL_USER_PROFILE: "Korisnički profil",
  LABEL_GENDER: "Pol",
  LABEL_MALE: "Muški",
  LABEL_FEMALE: "Ženski",
  LABEL_SELECTED_0_MUNICIPALITY: "Izabrano je 0 opština",
  LABEL_EVENT_DIFFICULTY: "Težina događaja",
  ACTIVITY_DURATION: "Trajanje aktivnosti",
  LABEL_ARTICLE_AREA: "Oblast članka",
  LABEL_ARTICLE_TYPE: "Tip članka",
  LABEL_ARTICLE_TITLE: "Naslov",
  LABEL_QUESTION_AREA: "Tip pitanja",
  LABEL_ARTICLE_SUMMARY: "Sažetak",
  LABEL_ARTICLE_SUMMARY_IMAGE: "Fotografija sažetka",
  LABEL_ARTICLE_HEADER_IMAGE: "Fotografija zaglavlja",
  LABEL_ARTICLE_BODY: "Sadržaj",
  LABEL_ARTICLE_BODY_HTML: "HTML sadržaj",
  LABEL_ARTICLE_URL: "Web link",
  LABEL_ARTICLE_VIDEO: "https://www.youtube.com/watch?v=  ",
  LABEL_DISPLAY_ORDER: "Prikazni redosled",
  LABEL_ARTICLE_INSERT_TIMESTAMP: "Vreme kreiranja",
  LABEL_HEIGHT_CM: "Visina (cm)",
  LABEL_WEIGHT_KG: "Težina (kg)",
  LABEL_REGISTRATION_TITLE: "POTVRDA REGISTRACIJE",
  LABEL_REGISTRATION_STATUS_SUCCESS: "Registracija je uspešno izvršena. Možete ugasiti Web pretraživač i ponovo pokrenuti aplikaciju Multipla skleroza.",
  LABEL_REGISTRATION_STATUS_FAILURE: "Neuspešna registracija.",
  LABEL_PAGE_IN_PROGRESS: "Stranica je u izradi.",
  LABEL_RANG_LIST_MONTH: "Mesečna lista",
  LABEL_RANG_LIST_YEAR: "Godišnja lista",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Da li ste sigurni da želite da se odjavite?",
  LABEL_DOCTOR_WITHOUT_DOCTORS_OFFICE: "Da biste pristupili ordinaciji neophodno je da Vaš nalog bude verifikovan od strane administratora.",
  LABEL_TAKE_CONVERSATION: "Preuzmi",
  LABEL_DR: "dr",
  LABEL_TAKEN_DOCTOR_TEXT: "razmatra odgovor",
  LABEL_EMPTY_CONVERSATION_PICK_MESSAGE_1: "Molimo",
  LABEL_EMPTY_CONVERSATION_PICK_MESSAGE_2: "kliknite",
  LABEL_EMPTY_CONVERSATION_PICK_MESSAGE_3: "na poruku za prikaz",
  LABEL_CLOSED_MESSAGE_NOT_NEEDED_RESPONSE: "Odgovor na ovu poruku nije potreban",

  TAB_ARTICLE_SUMMARY: "Osnovni podaci",
  TAB_ARTICLE_CONTENT: "Sadržaj",
  TAB_ARTICLE_PREVIEW_SUMMARY: "Kratak pregled",
  TAB_ARTICLE_PREVIEW_CONTENT: "Pregled sadržaja",

  START_TIME: "startTime",
  // Chart labels
  LABEL_COLLECTED: "Sakupio",
  LABEL_MISSING: "Fali",

  TABLE_EMPTY_MESSAGE: "Nema podataka",

  //Column title
  COLUMN_TITLE_RANK: "Rang",
  COLUMN_TITLE_CHOSEN_NAME: "Izabrano ime",
  COLUMN_TITLE_DISTANCE: "Pređeno",

  //Dialog header
  TITLE_DIALOG_CREATE_ARTICLE: "Kreiranje članka",
  TITLE_DIALOG_DELETE_ARTICLE: "Brisanje članka: ",
  TITLE_DIALOG_UPDATE_ARTICLE: "Izmena članka: ",
  TITLE_DIALOG_READ_ARTICLE: "Detalji članka: ",
  TITLE_DIALOG_CREATE_FAQ: "Kreiranje čestog pitanja",
  TITLE_DIALOG_DELETE_FAQ: "Brisanje čestog pitanja: ",
  TITLE_DIALOG_UPDATE_FAQ: "Izmena čestog pitanja: ",
  TITLE_DIALOG_READ_FAQ: "Detalji čestog pitanja: ",
  TITLE_DIALOG_LEKAR_CREATE: "Kreiranje lekara",
  TITLE_DIALOG_LEKAR_UPDATE: "Izmena lekara",
  TITLE_DIALOG_LEKAR_DELETE: "Brisanje lekara",
  TITLE_DIALOG_LEKAR_READ: "Detalji lekara",

  //Title messages
  TITLE_MESSAGES_REGISTRATION_ERROR: "Greška prilikom registracije ",
  TITLE_MESSAGES_RESET_PASSWORD: "Greška prilikom reseta lozinke ",
  TITLE_MESSAGES_ERROR: "Greška",
  TITLE_MESSAGES_CREATE_EVENT_SUCCESS: "Uspešno kreiran događaj",
  TITLE_MESSAGES_UPDATE_EVENT_SUCCESS: "Uspešno izmenjen događaj",
  TITLE_MESSAGES_DELETE_EVENT_SUCCESS: "Uspešno izbrisan događaj",
  TITLE_MESSAGES_CREATE_ARTICLE_SUCCESS: "Uspešno kreiran članak",
  TITLE_MESSAGES_UPDATE_ARTICLE_SUCCESS: "Uspešno izmenjen članak",
  TITLE_MESSAGES_DELETE_ARTICLE_SUCCESS: "Uspešno izbrisan članak",
  TITLE_MESSAGES_UPDATE_USER_SUCCESS: "Uspešno izmenjen korisnik ",
  TITLE_MESSAGES_CREATE_ERROR: "Greška prilikom kreiranja ",
  TITLE_MESSAGES_UPDATE_ERROR: "Greška prilikom izmene ",
  TITLE_MESSAGES_DELETE_ERROR: "Greška prilikom brisanja ",
  TITLE_MESSAGES_UPLOAD_ERROR: "Greška prilikom učitavanja datoteke",
  TITLE_MESSAGES_CREATE_FAQ_SUCCESS: "Uspešno kreirao pitanje",
  TITLE_MESSAGES_UPDATE_FAQ_SUCCESS: "Uspešno izmenjeno pitanje",
  TITLE_MESSAGES_DELETE_FAQ_SUCCESS: "Uspešno izbrisano pitanje",
  TITLE_MESSAGES_CREATE_DOCTOR_SUCCESS: "Uspešno kreiranje lekara",
  TITLE_MESSAGES_DELETE_DOCTOR_SUCCESS: "Uspešno izbrisan lekar",
  TITLE_MESSAGES_UPDATE_DOCTOR_SUCCESS: "Uspešno izmenjen lekar",
  TITLE_MESSAGES_ADDED_DOCTOR_CLINIC_SUCCESS: "Uspešno dodat lekar u ordinaciju",
  TITLE_MESSAGES_REMOVED_DOCTOR_CLINIC_SUCCESS: "Uspešno izbrisan lekar iz ordinacije",
  TITLE_MESSAGES_USER_MODERATOR: "Korisniku je uspešno promenjen moderatorski status",
  TITLE_MESSAGES_USER_FORUM_ACCESS: "Korisniku je uspešno promenjeno pravo pristupa Ms Cafeu",

  //Messages
  MESSAGES_FIRSTNAME_FIELD: "Polje 'Ime' je obavezno",
  MESSAGES_LASTNAME_FIELD: "Polje 'Prezime' je obavezno",
  MESSAGES_CHOSEN_NAME_FIELD: "Polje 'Izabrano ime' je obavezno",
  MESSAGES_EMAIL_FIELD: "Polje 'Email' je obavezno",
  MESSAGES_PASSWORD_FIELD: "Polje 'Lozinka' je obavezno",
  MESSAGES_PASSWORD_CONFIRM_FIELD: "Polje 'Potvrda lozinke' je obavezno",
  MESSAGES_PASSWORD_LENGTH: "Lozinka mora sadržati najmanje 8 alfa-numeričkih karaktera",
  MESSAGES_NOT_EQUAL_PASSWORD: "Lozinka i potvrda lozinke moraju biti iste",
  MESSAGES_UPLOAD_SIZE_50KB_ERROR: "Datoteka mora biti manja od 50 Kb.",
  MESSAGES_CHOOSE_IMAGE: "Izaberite",
  MESSAGES_FILE_SIZE_ERROR: "Maksimalna veličina slike je 64 KB",
  MESSAGES_UPLOAD_IMAGE_ERROR: "Neuspešno učitvanje slike",
  MESSAGES_REGISTRATION_RESEND: "Ukoliko Vam nije stigao mejl, kliknite na dugme Pošalji opet mejl",
  MESSAGES_EMAIL_NOT_VALID: "Uneta mejl adresa nije u dobrom formatu.",
  MESSAGES_DOCTOR_EMPTY: "Podaci o doktoru su obavezni",
  MESSAGES_CLINIC_EMPTY: "Podaci o ordinaciji su obavezni",
  MESSAGES_PATIENT_EMPTY: "Podaci o pacijentu su obavezni",
  MESSAGES_MESSAGE_TEXT_EMPTY: "Tekst poruke je obavezan",

  // LoginPage
  LOGINPAGE_INFO_01: "Svakih 8 sekundi neko umre od dijabetesa",
  LOGINPAGE_INFO_02: "Svakih 5 minuta neko sa dijabetesom doživi srčani udar",
  LOGINPAGE_INFO_03: "Svakih 30 sekundi neko podlegne amputaciji ekstremiteta zbog dijabetesa",
  LOGINPAGE_INFO_04: "90% slučajeva dijabetesa tip 2 je moglo biti prevenirano",
  LOGINPAGE_INFO_05: "Vreme je za akciju",

  //userProfile
  MESSAGE_EDIT_PROFILE_SUCCESS: "Uspešno ste izmenili korisničke podatke.",
  GENDER_MALE: "Muški",
  GENDER_FEMALE: "Ženski",
  EDIT_USER_PROFILE: "Izmena korisničkog profila",
  EMAIL: "Email",
  DATE_OF_BIRTH: "Datum rođenja",
  GENDER: "Pol",
  PLACEHOLDER_GENDER: "Odaberite pol",
  PRIVACY_POLICY_ACCEPTED: "Politika privatnosti",
  SAVE_CHANGES: "Sačuvaj",
  FIRST_NAME: "Ime",
  LAST_NAME: "Prezime",

  //passwordChange
  USER_PASSWORD_CHANGE: "Promena lozinke",
  USER_PROFILE_PASSWORD_CHANGE_ERROR: "Greška prilikom promene lozinke.",
  USER_PROFILE_MESSAGES_NEW_PASSWORD: "Morate uneti novu lozinku.",
  USER_PROFILE_MESSAGES_OLD_PASSWORD: "Morate uneti staru lozinku.",
  USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION: "Morate uneti potvrdu nove lozinke.",
  USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME: "Nova lozinka i potvrda lozinke nisu iste.",
  USER_PROFILE_CHANGE_PASSWORD_SUCCESS: "Uspešno promenjena lozinka.",
  USER_PROFILE_BUTTON_CANCEL: "Odustani",
  USER_PROFILE_BUTTON_SAVE_NEW_PASSWORD: "Sačuvaj novu lozinku",
  USER_PROFILE_OLD_PASSWORD: "Stara lozinka",
  USER_PROFILE_NEW_PASSWORD: "Nova lozinka",
  USER_PROFILE_CONFIRM_NEW_PASSWORD: "Potvrda nove lozinke",
  USER_PROFILE_ADD_USER: "Dodaj lekara",

  //MsCafe
  USER_BLOCK: "Blokirajte korisnika",
  USER_UNBLOCK: "Odblokirajte korisnika",
  USER_ADD_MODERATOR_RIGHT: "Dodajte moderatorsko pravo",
  USER_REMOVE_MODERATOR_RIGHT: "Uklonite moderatorsko pravo",

  STATISTIC: "Statistika",
  REGISTER_USERS: "Registrovanih korisnika",
  REGISTERED_USERS: "Registrovani korisnici",
  READ_ARTICLE: "Pročitanih članaka",
  MS_CAFE_USER: "Korisnika MS Cafe-a",
  PATIENT_MESSAGES: "Poruka upućenih lekarima",
  DOCTOR_MESSAGES: "Odgovora od strane lekara",
  MS_CAFE_MESSAGES: "Odgovora na temu u MS Cafe-u",
  DIALOG_PATIENT_MESSAGES: "Odgovori poslati od strane lekara",
  DIALOG_MS_CAFE_MESSAGES: "Poslati odgovori na temu",
  DIALOG_ARTICLE_MESSAGES: "Pročitani članci",
  REPLY_COUNT: "Broj odgovora",
  DISCUSSION_NAME: "Naziv teme",
  SPECIALIZATION: "Specijalizacija",
  PATIENTS: "Pacijenti",
  FOLLOWERS: "Pratioci",
  USER_TYPE: "Tip korisnika",
  USER_COUNT: "Broj korisnika",

  CLICK_COUNT: "Broj klikova",
  ARTICLE_NAME: "Naziv članka",

  NOTIFICATION: "Notifikacije",
  CREATE_NOTIFICATION: "Kreiranje obaveštenja",
  SEND_NOTIFICATION_SUCCESS: "Uspešno ste poslali notifikaciju korisnicima!",
  NOTIFICATION_PLACEHOLDER: " Unesite tekst notifikacije",
  NOTIFICATION_DIALOG_TEXT: "Slanje notifikacije biće vidljivo svim korisnicima aplikacije.",
  NOTIFICATION_DIALOG_TITLE: "Potvrda akcije",

  VISIBILITY_RIGHT_MAIN_DESCRIPTION: "Odaberite funkcionalnosti koje će se prikazivati u donjem navigacionom meniju",
  VISIBILITY_RIGHT_DESCRIPTION_MSCAFE: "Portal namenjen korisnicima aplikacije Moj MS Svet, koji omogućava vođenje grupnih diskusija.",
  VISIBILITY_RIGHT_DESCRIPTION_ORDINACIJA: "Omogućava korisnicima da  šalju upite grupi lekara određene specijalizacije.",
  VISIBILITY_RIGHT_TITLE_ORDINACIJA: "Ordinacija",
  VISIBILITY_RIGHT_TITLE_MSCAFE: "MS Cafe",
  VISIBILITY_RIGHT_OPTION_HEADER: "Potvrda akcije",
  VISIBILITY_RIGHT_OPTION_APPLY: "Odabrana funkcionalnost će biti prikazana svim korisnicima aplikacije",
  VISIBILITY_RIGHT_OPTION_REMOVE: "Odabrana funkcionalnost će biti uklonjena svim korisnicima aplikacije",
};

export default Labels;
