import actions from "./actions";
import { InitialStateDialogAddDoctorType } from "./initialStateDialogAddDoctor";

export default function reducerDialogAddDoctor(state: InitialStateDialogAddDoctorType, action: any) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        doctors: action.value,
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        doctors: [],
      };
    }
    case actions.LOADING: {
      return {
        ...state,
        doctorsLoading: action.value,
      };
    }
    default:
      return state;
  }
}
