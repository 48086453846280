import { SetStateAction, useContext, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import axios, { AxiosResponse } from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig, checkEmpty, handleAxiosCallError, skeletonTimeout } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import MessageReadDto from "../../model/messages/MessageReadDto";
import moment from "moment/moment";
import MessageUpdateDto from "../../model/messages/MessageUpdateDto";
import MessageCreateDto from "../../model/messages/MessageCreateDto";
import MessageType from "../../infrastructure/MessageType";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export interface OrdinacijaLekarLogicalType {
  messagesList: Array<MessageReadDto>;
  messagesListLoading: boolean;
  setSeen: (message: MessageReadDto) => void;
  conversation: Array<MessageReadDto>;
  openConversation: (message: MessageReadDto) => void;
  isVisibleMessageInput: boolean;
  openMessageInput: () => void;
  closeMessageInput: () => void;
  sendMessage: (messageText: string) => void;
  sendMessageInputValue: string;
  setSendMessageInputValue: React.Dispatch<SetStateAction<any>>;
  choosenMessage: MessageReadDto | undefined;
  conversationLoading: boolean;
  getConversationMessages: (message: MessageReadDto | undefined) => void;
  fetchMoreMessages: () => void;
  allMessagesLoaded: boolean;
  fetchMoreConversation: () => void;
  allConversationMessagesLoaded: boolean;
  isConversationTaken: boolean;
  latestDoctorMessage: MessageReadDto | undefined;
  onSetMessageAsClosed: (messageId: number) => void;
  getMessages: () => void;
}

export default function OrdinacijaLekarLogical() {
  const { authData, showMessage } = useContext(AppContext);
  const [messagesList, setMessagesList] = useState<Array<MessageReadDto>>([]);
  const [messagesListLoading, setMessagesListLoading] = useState<boolean>(false);
  const [conversation, setConversation] = useState<Array<MessageReadDto>>([]);
  const [choosenMessage, setChoosenMessage] = useState<MessageReadDto>();
  const [isVisibleMessageInput, setIsVisibleMessageInput] = useState<boolean>(false);
  const [sendMessageInputValue, setSendMessageInputValue] = useState<string>("");
  const [conversationLoading, setConversationLoading] = useState<boolean>(false);
  const [allMessagesLoaded, setAllMessagesLoaded] = useState<boolean>(false);
  const [allConversationMessagesLoaded, setAllConversationMessagesLoaded] = useState<boolean>(false);
  const [isConversationTaken, setIsConversationTaken] = useState<boolean>(false);
  const choosenMessageRef = useRef<MessageReadDto | undefined>();
  const pageMessagesRef = useRef<number>(0);
  const pageConversationRef = useRef<number>(0);
  const latestDoctorMessage = conversation.find((message: MessageReadDto) => message.doctorUser === null);

  useEffectOnce(() => {
    if (authData.currentUser.clinicType) {
      getMessages();
    }
  });

  const getMessages = () => {
    setMessagesListLoading(true);
    const startTime = moment(new Date());
    axios
      .get(`${Endpoint.MESSAGES_LIST_FOR_CLINIC}/${authData.currentUser.clinicType?.id}`, axiosConfig(authData.token, { page: pageMessagesRef.current, size: 10 }))
      .then((res: AxiosResponse) => {
        setMessagesList(res.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      })
      .finally(() => {
        skeletonTimeout(setMessagesListLoading, startTime);
      });
  };

  const setSeen = (message: MessageReadDto) => {
    axios
      .put(`${Endpoint.MESSAGES_LIST}/message/${message.id}/seen`, { seen: !message.seen }, axiosConfig(authData.token))
      .then(() => {
        let currentMessages = [...messagesList];
        currentMessages.map((item: MessageReadDto) => {
          if (message.id === item.id) {
            item.seen = !item.seen;
          }
          return item;
        });
        setMessagesList(currentMessages);
        setConversation([]);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const openConversation = (message: MessageReadDto) => {
    setChoosenMessage(message);
    choosenMessageRef.current = message;
    const startTime = moment(new Date());
    if (message.seen) {
      getConversationMessages(message);
      setIsConversationTaken(choosenMessageRef?.current?.patientUser.taken === true && authData.currentUser.id !== choosenMessageRef?.current?.patientUser.takenUser?.id);
    } else {
      choosenMessageRef.current = message;
      setConversationLoading(true);
      const resConversation = axios.get(
        `${Endpoint.MESSAGES_LIST_FOR_CLINIC}/${authData.currentUser.clinicType?.id}/user/${message.patientUser.id}`,
        axiosConfig(authData.token, { page: 0, size: 10 })
      );
      const resSetSeen = axios.put(`${Endpoint.MESSAGES_LIST}/message/${message.id}/seen`, { seen: !message.seen }, axiosConfig(authData.token));
      axios
        .all([resConversation, resSetSeen])
        .then(
          axios.spread((responseConversation, responseSetSeen) => {
            setConversation(responseConversation.data.data);
            let currentMessages = [...messagesList];
            currentMessages.map((item: MessageReadDto) => {
              if (message.id === item.id) {
                item.seen = !item.seen;
              }
              return item;
            });
            setMessagesList(currentMessages);
            setIsVisibleMessageInput(authData.currentUser.id === choosenMessageRef?.current?.patientUser.takenUser?.id);
            setIsConversationTaken(choosenMessageRef?.current?.patientUser.taken === true && authData.currentUser.id !== choosenMessageRef?.current?.patientUser.takenUser?.id);
          })
        )
        .catch((e) => {
          handleAxiosCallError(showMessage, e);
        })
        .finally(() => {
          skeletonTimeout(setConversationLoading, startTime);
        });
    }
  };

  const toggleTakenUser = (taken: boolean) => {
    const currentMessages = [...messagesList];
    currentMessages.map((item: MessageReadDto) => {
      if (choosenMessage?.id === item.id) {
        item.patientUser.taken = taken;
        item.patientUser.takenUser = taken ? { ...authData.currentUser } : null;
      }
      return item;
    });
    setMessagesList(currentMessages);
  };

  const openMessageInput = () => {
    let changeData: MessageUpdateDto = { takenUser: { id: authData?.currentUser?.id }, taken: true };
    axios
      .put(`${Endpoint.USER_LIST}/user/${choosenMessage?.patientUser?.id}/taken`, changeData, axiosConfig(authData.token))
      .then(() => {
        setIsVisibleMessageInput(true);
        pageMessagesRef.current = 0;
        toggleTakenUser(true);
        getConversationMessages(choosenMessage);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const closeMessageInput = () => {
    let changeData: MessageUpdateDto = { takenUser: null, taken: false };
    axios
      .put(`${Endpoint.USER_LIST}/user/${choosenMessage?.patientUser?.id}/taken`, changeData, axiosConfig(authData.token))
      .then(() => {
        setIsVisibleMessageInput(false);
        pageMessagesRef.current = 0;
        toggleTakenUser(false);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const validateNewMessage = (message: MessageCreateDto) => {
    if (checkEmpty(message?.doctorUser)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_DOCTOR_EMPTY, "");
      return false;
    }
    if (checkEmpty(message?.patientUser)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_PATIENT_EMPTY, "");
      return false;
    }
    if (checkEmpty(message?.clinicType)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_CLINIC_EMPTY, "");
      return false;
    }
    if (checkEmpty(message?.messageText)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_MESSAGE_TEXT_EMPTY, "");
      return false;
    }
    return true;
  };

  const getConversationMessages = (message: MessageReadDto | undefined) => {
    if (message) {
      setConversationLoading(true);
      const startTime = moment(new Date());
      pageConversationRef.current = 0;
      axios
        .get(`${Endpoint.MESSAGES_LIST_FOR_CLINIC}/${authData.currentUser.clinicType?.id}/user/${message.patientUser.id}`, axiosConfig(authData.token, { page: 0, size: 10 }))
        .then((res: AxiosResponse) => {
          setConversation(res.data.data);
          setIsVisibleMessageInput(authData.currentUser.id === choosenMessageRef?.current?.patientUser.takenUser?.id);
        })
        .catch((e) => {
          handleAxiosCallError(showMessage, e);
        })
        .finally(() => {
          skeletonTimeout(setConversationLoading, startTime);
        });
    }
  };

  const sendMessage = (messageText: string) => {
    let messageData: MessageCreateDto = {
      doctorUser: { id: authData.currentUser.id },
      patientUser: { id: choosenMessage?.patientUser.id },
      clinicType: { id: choosenMessage?.clinicType.id, name: choosenMessage?.clinicType.name },
      messageText: messageText,
      seen: choosenMessage?.seen ?? false,
    };

    if (!validateNewMessage(messageData)) {
      return;
    }

    axios
      .post(Endpoint.MESSAGES_LIST, messageData, axiosConfig(authData.token))
      .then(() => {
        setConversationLoading(true);
        const startTime = moment(new Date());
        axios
          .get(`${Endpoint.MESSAGES_LIST_FOR_CLINIC}/${authData.currentUser.clinicType?.id}/user/${choosenMessage?.patientUser.id}`, axiosConfig(authData.token, { page: 0, size: 10 }))
          .then((response: AxiosResponse) => {
            setConversation(response.data.data);
            setSendMessageInputValue("");
          })
          .catch((error) => {
            handleAxiosCallError(showMessage, error);
          })
          .finally(() => {
            skeletonTimeout(setConversationLoading, startTime);
          });
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const fetchMoreMessages = () => {
    pageMessagesRef.current = pageMessagesRef.current + 1;
    axios
      .get(`${Endpoint.MESSAGES_LIST_FOR_CLINIC}/${authData.currentUser.clinicType?.id}`, axiosConfig(authData.token, { page: pageMessagesRef.current, size: 10 }))
      .then((res: AxiosResponse) => {
        // dodat timeout samo za prezentacione svrhe, bice sklonjen
        setTimeout(() => {
          if (res.data.data.length === 0) {
            setAllMessagesLoaded(true);
          } else {
            setMessagesList([...messagesList, ...res.data.data]);
          }
        }, 1000);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const fetchMoreConversation = () => {
    pageConversationRef.current = pageConversationRef.current + 1;
    axios
      .get(
        `${Endpoint.MESSAGES_LIST_FOR_CLINIC}/${authData.currentUser.clinicType?.id}/user/${choosenMessage?.patientUser.id}`,
        axiosConfig(authData.token, { page: pageConversationRef.current, size: 10 })
      )
      .then((response: AxiosResponse) => {
        setTimeout(() => {
          if (response.data.data.length === 0) {
            setAllConversationMessagesLoaded(true);
          } else {
            setConversation([...conversation, ...response.data.data]);
          }
        }, 1000);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const onSetMessageAsClosed = (messageId: number) => {
    axios
      .put(`${Endpoint.MESSAGES_LIST}/${messageId}/closed`, {}, axiosConfig(authData.token))
      .then(() => {
        getConversationMessages(choosenMessage);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  return {
    messagesList,
    messagesListLoading,
    setSeen,
    conversation,
    setConversation,
    openConversation,
    isVisibleMessageInput,
    openMessageInput,
    closeMessageInput,
    sendMessage,
    sendMessageInputValue,
    setSendMessageInputValue,
    choosenMessage,
    conversationLoading,
    getConversationMessages,
    fetchMoreMessages,
    allMessagesLoaded,
    fetchMoreConversation,
    allConversationMessagesLoaded,
    isConversationTaken,
    latestDoctorMessage,
    onSetMessageAsClosed,
    getMessages,
  };
}
