import { useContext, useState } from "react";
import { useEffectOnce } from "react-use";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig, handleAxiosCallError } from "../../infrastructure/Utils";
import axios, { AxiosResponse } from "axios";
import GroupedMessageReadDto from "../../model/messages/GroupedMessageRead";
import GroupedForumMessageReadDto from "../../model/messages/GroupedForumMessageRead";
import GroupedArticleReadDto from "../../model/article/GroupedArticleRead";

export interface StatisticLogicalType {
  registerUserNumber: number;
  articleNumber: number;
  msCafeUserNumber: number;
  patientMessageNumber: number;
  doctorMessageNumber: number;
  msCafeMessageNumber: number;
  displayMessageDialog: boolean;
  displayForumDialog: boolean;
  displayUserDialog: boolean;
  displayArticleDialog: boolean;
  openMessageDialog: () => void;
  closeDialog: () => void;
  openMSCafeDialog: () => void;
  openArticleDialog: () => void;
  openUserDialog: () => void;
  groupedMessageList: Array<GroupedMessageReadDto>;
  groupedForumMessageList: Array<GroupedForumMessageReadDto>;
  groupedArticleList: Array<GroupedArticleReadDto>;
  accountForAnotherNumber: number;
}

export default function OrdinacijaLogical() {
  const { authData, showMessage } = useContext(AppContext);

  const [registerUserNumber, setRegisterUserNumber] = useState<number>(0);
  const [accountForAnotherNumber, setAccountForAnotherNumber] = useState<number>(0);
  const [articleNumber, setArticleNumber] = useState<number>(0);
  const [msCafeUserNumber, setMSCafeUserNumber] = useState<number>(0);
  const [patientMessageNumber, setPatientMessageNumber] = useState<number>(0);
  const [doctorMessageNumber, setDoctorMessageNumber] = useState<number>(0);
  const [msCafeMessageNumber, setMSCafeMessageNumber] = useState<number>(0);
  const [displayMessageDialog, setMessageDisplayDialog] = useState<boolean>(false);
  const [displayUserDialog, setUserDisplayDialog] = useState<boolean>(false);
  const [displayForumDialog, setForumDisplayDialog] = useState<boolean>(false);
  const [displayArticleDialog, setArticleDisplayDialog] = useState<boolean>(false);
  const [groupedMessageList, setGroupedMessageList] = useState<Array<GroupedMessageReadDto>>([]);
  const [groupedForumMessageList, setGroupedForumMessageList] = useState<Array<GroupedForumMessageReadDto>>([]);
  const [groupedArticleList, setGroupedArticleList] = useState<Array<GroupedArticleReadDto>>([]);

  useEffectOnce(() => {
    getRegisterUserNumber();
    getArticleNumber();
    getMSCafeUserNumber();
    getDoctorMessageNumber();
    getPatientMessageNumber();
    getMSCafeMessageNumber();
  });

  const getRegisterUserNumber = () => {
    axios
      .get(Endpoint.USER_COUNT_REGISTER, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setRegisterUserNumber(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const getArticleNumber = () => {
    axios
      .get(Endpoint.ARTICLE_LOG_LIST_COUNT, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setArticleNumber(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };
  const getMSCafeUserNumber = () => {
    axios
      .get(Endpoint.MS_CAFE_COUNT_USER, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setMSCafeUserNumber(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const getDoctorMessageNumber = () => {
    axios
      .get(Endpoint.MASSEGE_COUNT_DOCTOR, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setDoctorMessageNumber(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const getPatientMessageNumber = () => {
    axios
      .get(Endpoint.MESSAGE_COUNT_PATIENT, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setPatientMessageNumber(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const getMSCafeMessageNumber = () => {
    axios
      .get(Endpoint.FORUM_MESSAGE_COUNT, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setMSCafeMessageNumber(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const openMessageDialog = () => {
    axios
      .get(Endpoint.GROUPED_MESSAGE_COUNT, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setMessageDisplayDialog(true);
        setGroupedMessageList(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const openUserDialog = () => {
    axios
      .get(Endpoint.USER_COUNT_FOR_ANOTHER_ACCOUNT, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setUserDisplayDialog(true);
        setAccountForAnotherNumber(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const closeDialog = () => {
    setMessageDisplayDialog(false);
    setForumDisplayDialog(false);
    setArticleDisplayDialog(false);
    setUserDisplayDialog(false);
  };

  const openMSCafeDialog = () => {
    axios
      .get(Endpoint.GROUPED_MSCAFE_MESSAGE_COUNT, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setForumDisplayDialog(true);
        setGroupedForumMessageList(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const openArticleDialog = () => {
    axios
      .get(Endpoint.GROUPED_ARTICLE_LOG_LIST_COUNT, axiosConfig(authData.token))
      .then((response: AxiosResponse) => {
        setArticleDisplayDialog(true);
        setGroupedArticleList(response.data.data);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  return {
    registerUserNumber,
    articleNumber,
    msCafeUserNumber,
    patientMessageNumber,
    doctorMessageNumber,
    msCafeMessageNumber,
    displayMessageDialog,
    displayForumDialog,
    displayArticleDialog,
    openMessageDialog,
    closeDialog,
    openMSCafeDialog,
    openArticleDialog,
    groupedMessageList,
    groupedForumMessageList,
    groupedArticleList,
    displayUserDialog,
    openUserDialog,
    accountForAnotherNumber,
  };
}
