import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
interface GroupedUserListProps {
  countForAnotherAccount: number;
  countRegisteredUser: number;
}

export default function GroupedUserList({ countForAnotherAccount, countRegisteredUser }: GroupedUserListProps) {
  return (
    <div className="p-5">
      <DataTable
        value={[
          { title: Labels.PATIENTS, size: countRegisteredUser - countForAnotherAccount },
          { title: Labels.FOLLOWERS, size: countForAnotherAccount },
        ]}
        emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
        className="groupedForumMessageList"
      >
        <Column field={"title"} header={Labels.USER_TYPE} />
        <Column field={"size"} header={Labels.USER_COUNT} />
      </DataTable>
    </div>
  );
}
