import React, { ChangeEvent, useContext, useState } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toolbar } from "primereact/toolbar";
import { AppContext } from "../../Store";
import MessageType from "../../infrastructure/MessageType";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import UserPasswordChangeDto from "../../model/user/UserPasswordChangeDto";
import axios from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig, handleAxiosCallError } from "../../infrastructure/Utils";
import { Panel } from "primereact/panel";
import { useHistory } from "react-router-dom";
import { UserRole, UserRoleType } from "../../controller/UserRole";

export default function ChangePassword() {
  const { showMessage, authData, setShowBlockUI } = useContext(AppContext);
  const [userChangePassword, setUserChangePassword] = useState<UserPasswordChangeDto>({ currentPassword: "", newPassword: "", confirmPassword: "" });
  const history = useHistory();
  const changeUserData = (e: ChangeEvent<HTMLInputElement>) => {
    setUserChangePassword({
      ...userChangePassword!,
      [e.target.name]: e.target.value,
    });
  };
  const { isDoctor }: UserRoleType = UserRole();

  const validateInput = () => {
    if (userChangePassword?.currentPassword === "") {
      showMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_OLD_PASSWORD);
      return false;
    }
    if (userChangePassword?.newPassword === "") {
      showMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_NEW_PASSWORD);
      return false;
    }
    if (userChangePassword?.confirmPassword === "") {
      showMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION);
      return false;
    }
    if (userChangePassword?.confirmPassword !== userChangePassword?.newPassword) {
      showMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME);
      return false;
    }
    return true;
  };

  const passwordChange = () => {
    if (!validateInput()) {
      return;
    }
    setShowBlockUI(true);
    axios
      .post(`${Endpoint.USER}/userList/${authData?.currentUser.id}/changePassword`, userChangePassword, axiosConfig(authData!.token))
      .then((response: any) => {
        showMessage(MessageType.SUCCESS, Labels.USER_PROFILE_CHANGE_PASSWORD_SUCCESS, "");
        setShowBlockUI(false);
        if (isDoctor) {
          history.push("/ordinacijaLekar");
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const leftContents = () => <Button label={Labels.SAVE_CHANGES} className="p-button-primary button-save" onClick={() => passwordChange()} />;

  const rightContents = () => (
    <Button
      label={Labels.USER_PROFILE_BUTTON_CANCEL}
      className="p-button-warning button-cancel-update"
      onClick={() => {
        if (isDoctor) {
          history.push("/ordinacijaLekar");
        } else {
          history.push("/");
        }
      }}
    />
  );

  return (
    <div className="p-grid p-fluid layout-profile">
      <Panel header={Labels.EDIT_USER_PROFILE}>
        <div className="p-col-12 panel-registration">
          <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center div-size">
            <div className="p-col-12  login-input-password">
              <label className="edit-user-label">{Labels.USER_PROFILE_OLD_PASSWORD}</label>
              <Password name="currentPassword" feedback={false} autoComplete="new-password" onChange={changeUserData} value={userChangePassword.currentPassword} toggleMask />
            </div>

            <div className="p-col-12 login-input-password">
              <label className="edit-user-label">{Labels.USER_PROFILE_NEW_PASSWORD}</label>
              <Password name="newPassword" autoComplete="new-password" onChange={changeUserData} value={userChangePassword.newPassword} toggleMask />
            </div>

            <div className="p-col-12 login-input-password">
              <label className="edit-user-label">{Labels.USER_PROFILE_CONFIRM_NEW_PASSWORD}</label>
              <Password name="confirmPassword" feedback={false} autoComplete="new-password" onChange={changeUserData} value={userChangePassword.confirmPassword} toggleMask />
            </div>
            <div className="p-col-12 login-button">
              <Toolbar left={leftContents} right={rightContents} />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
