import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { ChangeEvent, useContext, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useHistory } from "react-router-dom";
import Endpoint from "../../infrastructure/Endpoint";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, handleAxiosCallError, imageExtention } from "../../infrastructure/Utils";
import UserReadDto from "../../model/user/UserReadDto";
import { AppContext } from "../../Store";
import { UserRole, UserRoleType } from "../../controller/UserRole";

export default function UserProfile() {
  const { updateAuthData, showMessage, authData, setShowBlockUI } = useContext(AppContext);
  const [userChange, setUserChange] = useState<UserReadDto>(authData!.currentUser);
  const [avatarChangeVisible, setAvatarChangeVisible] = useState(false);
  const history = useHistory();
  const [avatar, setAvatar] = useState<any>({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: "",
  });
  const [editor, setEditor] = useState<any>();
  const changeUserData = (e: ChangeEvent<HTMLInputElement>) => {
    setUserChange({
      ...userChange!,
      [e.target.name]: e.target.value,
    });
  };
  const { isDoctor }: UserRoleType = UserRole();

  const _handleFileChange = (e: any) => {
    setAvatar({ ...avatar, img: e.target.files[0] });
  };

  const handleScale = (e: any) => {
    const scale = parseFloat(e.target.value);
    setAvatar({ ...avatar, zoom: scale });
  };

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      editProfilePicture(croppedImg.substr(croppedImg.indexOf(",") + 1));
      setAvatarChangeVisible(false);
    }
  };

  const setEditorRef = (editor: any) => {
    setEditor(editor);
  };

  const editUser = () => {
    setShowBlockUI(true);
    axios
      .put(`${Endpoint.USER}/userList/${userChange?.id}`, userChange, axiosConfig(authData!.token))
      .then((response: any) => {
        updateAuthData(
          JSON.stringify({
            token: authData?.token,
            currentUser: userChange,
          })
        );
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_EDIT_PROFILE_SUCCESS, "");
        setShowBlockUI(false);
        if (isDoctor) {
          history.push("/ordinacijaLekar");
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const cancelEdit = () => {
    setUserChange(authData!.currentUser);
    if (isDoctor) {
      history.push("/ordinacijaLekar");
    } else {
      history.push("/");
    }
  };

  const editProfilePicture = (img: any) => {
    setUserChange({ ...userChange!, profilePicture: img });
  };

  const avatarVisible = () => {
    setAvatarChangeVisible(true);
  };

  const avatarNotVisible = () => {
    setAvatarChangeVisible(false);
  };

  const leftContents = () => (
    <>
      <Button label={Labels.SAVE_CHANGES} className="p-button-success button-save" onClick={editUser} />
    </>
  );

  const rightContents = () => <Button label={Labels.BUTTON_CANCEL} className="p-button-danger button-cancel-update" onClick={cancelEdit} />;

  return (
    <div className="layout-profile">
      <Panel header={Labels.EDIT_USER_PROFILE} className="panel-width">
        <div className="p-grid p-justify-between">
          <div className="p-col-6">
            {!avatarChangeVisible && (
              <div
                onClick={avatarVisible}
                className="profile-picture"
                style={{
                  backgroundImage: userChange?.profilePicture ? `url(data:image/${imageExtention(userChange.profilePicture!)};base64,${userChange.profilePicture})` : `url(${Images.no_profile})`,
                }}
              />
            )}

            {avatarChangeVisible && (
              <div className="text-center">
                <div className="p-grid p-justify-center">
                  <AvatarEditor
                    className="margin-bottom-10 avatar-editor-size"
                    ref={setEditorRef}
                    image={avatar.img}
                    width={200}
                    height={200}
                    border={50}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={avatar.zoom}
                    rotate={0}
                  />
                </div>
                <br />
                <div className="p-grid p-justify-center">
                  <input hidden id="file" type="file" accept="image/*" onChange={_handleFileChange} />
                  <Button className="button-upload-image btn-small">
                    <label htmlFor="file">{Labels.BUTTON_UPLOAD_IMAGE}</label>
                  </Button>

                  <Button
                    className="button-save-picture btn-small"
                    label={Labels.BUTTON_SAVE}
                    onClick={() => {
                      handleSave();
                    }}
                  ></Button>
                  <Button className="button-cancel-picture btn-small" label={Labels.BUTTON_CANCEL} onClick={avatarNotVisible}></Button>
                </div>
                <br />
                <div className="p-grid p-justify-center">
                  <input name="scale" type="range" onChange={handleScale} min={avatar.allowZoomOut ? "0.1" : "1"} max="2" step="0.01" defaultValue="1" />
                </div>
              </div>
            )}
          </div>
          <div className="p-col-6 user-data">
            <div className="login-input">
              <label className="edit-user-label">{Labels.EMAIL}</label>
              <InputText name="email" className="p-col-12" value={userChange.email} disabled={true} />
            </div>
            <div className="login-input">
              <label className="edit-user-label">{Labels.FIRST_NAME}</label>
              <InputText name="firstname" className="p-col-12" value={userChange.firstname} onChange={changeUserData} />
            </div>
            <div className="login-input">
              <label className="edit-user-label">{Labels.LAST_NAME}</label>
              <InputText name="lastname" className="p-col-12" value={userChange.lastname} onChange={changeUserData} />
            </div>
          </div>
          <div className="p-col-12">
            <Toolbar left={leftContents} right={rightContents} />
          </div>
        </div>
      </Panel>
    </div>
  );
}
