import Microlink from "@microlink/react";
import axios from "axios";
import { now } from "moment";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { CascadeSelect } from "primereact/cascadeselect";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import ImageUploader from "react-images-upload";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, dateTimeBefore, getImage, isFormDisabled, quillColors } from "../../infrastructure/Utils";
import ArticleReadDto from "../../model/article/ArticleReadDto";
import EnumArticleAreaDisplayType from "../../model/article/EnumArticleAreaDisplayType";
import EnumArticleType from "../../model/article/EnumArticleType";
import { AppContext } from "../../Store";

interface CrudArticleProp {
  articleOperation: string;
  article?: ArticleReadDto;
  onCancel: Function;
  onCreateArticle: Function;
  onUpdateArticle: Function;
  onDeleteArticle: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudArticle(prop: CrudArticleProp) {
  const { articleOperation, article, onCreateArticle, onUpdateArticle, onDeleteArticle, dialogRef, setIndex, index } = prop;

  const { authData } = useContext(AppContext);

  const [isDisabled] = useState(isFormDisabled(articleOperation));
  const [articleCategoryList, setArticleCategoryList] = useState([]);
  const [articleTypeList, setArticleTypeList] = useState([]);
  const [selectedArticleType, setSelectedArticleType] = useState("");
  const [articleChange, setArticleChange] = useState<ArticleReadDto | undefined>(article);
  const [microlinkUrl, setMicrolinkUrl] = useState(article?.body);

  let [messages, setMessages] = useState<any>("");
  let articleCategories: { idC: any; nameC: any; articleAreas: { id: any; name: any }[] }[] = [];
  const [articleCategoriesArray, setArticleCategoriesArray] = useState<any>();

  useEffectOnce(() => {
    const requestActiveArticleCategoryList = axios.get(Endpoint.ACTIVE_ARTICLE_CATEGORY_LIST, axiosConfig(authData.token));
    const requestArticleTypeList = axios.get(Endpoint.ARTICLE_TYPE_LIST, axiosConfig(authData.token));

    if (article) {
      axios
        .get(Endpoint.ARTICLE_LIST + `/${article?.id}`, axiosConfig(authData.token))
        .then((responseArticleFull) => {
          setArticleChange(responseArticleFull.data.data);
        })
        .catch((error) => {
          showError(error.response.data.message, "");
        });
    }

    axios
      .all([requestActiveArticleCategoryList, requestArticleTypeList])
      .then(
        axios.spread((responseActiveArticleCategoryList, responseArticleTypeList) => {
          responseActiveArticleCategoryList.data.forEach((element: any) => {
            axios
              .get(Endpoint.ACTIVE_ARTICLE_AREA_LIST_BY_ARTICLE_CATEGORY + `/${element.id}`, axiosConfig(authData.token))
              .then((responseArticleAreaList) => {
                let articleAreasByCategory: { id: any; name: any }[] = [];
                responseArticleAreaList.data.forEach((element: any) => {
                  if (element?.articleAreaDisplayType?.code === EnumArticleAreaDisplayType.BACKEND) {
                    articleAreasByCategory.push({
                      id: element.id,
                      name: element.name,
                    });
                  }
                });
                articleCategoryTemplate(articleAreasByCategory, element);
              })
              .catch((error) => {
                showError(Labels.TITLE_MESSAGES_ERROR, error.response.data.message);
              });
          });
          setArticleCategoriesArray(articleCategories);
          setArticleCategoryList(responseActiveArticleCategoryList.data);
          const articleType = responseArticleTypeList.data.map((articleType: any) => {
            return { label: articleType.name, value: articleType.code };
          });
          setArticleTypeList(articleType);
        })
      )
      .catch((error) => {
        showError(error.response.data.message, "");
      });
  });

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateArticle(articleChange)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
    onUpdate: () => {
      onUpdateArticle(articleChange)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
    onDelete: () => {
      onDeleteArticle(articleChange?.id)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
  }));

  const articleCategoryTemplate = (articleAreasByCategory: any, articleCategory: any) => {
    articleCategories.push({
      idC: articleCategory.id,
      nameC: articleCategory.name,
      articleAreas: articleAreasByCategory,
    });
  };

  const showError = (summary: string, detail: string) => {
    messages.replace({
      severity: "error",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const onDrop = (picture: any) => {
    var reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        setArticleChange({ ...articleChange!, summaryImage: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showError(Labels.MESSAGES_UPLOAD_IMAGE_ERROR, "");
      };
    } else {
      setArticleChange({ ...articleChange!, summaryImage: undefined });
    }
  };

  const toolbar = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: quillColors }, { background: quillColors }], // dropdown with defaults from theme
      [{ align: [] }],
      ["link", "image"],
    ],
  };

  useEffect(() => {
    if (article?.articleType !== articleChange?.articleType) {
      setArticleChange({ ...articleChange!, body: "" });
    }
  }, [selectedArticleType]);

  return (
    <div className="layout-article">
      <div className="p-col-12 padding-0">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        ></Messages>
      </div>
      <TabView
        activeIndex={index}
        onTabChange={(e) => {
          setSelectedArticleType(articleChange!.articleType);
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_ARTICLE_SUMMARY}>
          <div className="scrollable-content">
            <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-12">
                  <div className="p-grid p-align-center p-nogutter">
                    <div className="p-col-4">
                      <div>{Labels.LABEL_ARTICLE_AREA + Labels.SPECIAL_CHAR_REQUIRED} </div>
                    </div>
                    <div className="p-col-8 p-fluid margin-top-bottom-5">
                      <CascadeSelect
                        options={articleCategoriesArray}
                        optionLabel={"name"}
                        onChange={(e) => {
                          setArticleChange({
                            ...articleChange!,
                            articleArea: e.value,
                          });
                        }}
                        value={articleChange && articleChange.articleArea}
                        optionGroupLabel={"nameC"}
                        optionGroupChildren={["articleAreas"]}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                  <div className="p-grid p-align-center p-nogutter">
                    <div className="p-col-4">
                      <div>{Labels.LABEL_ARTICLE_TYPE + Labels.SPECIAL_CHAR_REQUIRED}</div>
                    </div>
                    <div className="p-col-8 p-fluid margin-top-bottom-5">
                      <Dropdown
                        disabled={isDisabled}
                        value={articleChange && articleChange.articleType}
                        options={articleTypeList}
                        onChange={(e) => {
                          setArticleChange({
                            ...articleChange!,
                            articleType: e.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="p-grid p-align-center p-nogutter">
                    <div className="p-col-4">
                      <div>{Labels.LABEL_ARTICLE_TITLE + Labels.SPECIAL_CHAR_REQUIRED}</div>
                    </div>
                    <div className="p-col-8 p-fluid margin-top-bottom-5">
                      <InputText
                        disabled={isDisabled}
                        value={articleChange && articleChange.title}
                        onChange={(e: any) => {
                          setArticleChange({
                            ...articleChange!,
                            title: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {(articleChange?.articleType === EnumArticleType.HTML || articleChange?.articleType === EnumArticleType.PLAIN_TEXT) && (
                    <div className="p-grid p-align-center p-nogutter">
                      <div className="p-col-4">
                        <div>{Labels.LABEL_ARTICLE_SUMMARY}</div>
                      </div>
                      <div className="p-col-8 p-fluid margin-top-bottom-5">
                        <InputText
                          disabled={isDisabled}
                          value={articleChange && articleChange.summary}
                          onChange={(e: any) => {
                            setArticleChange({
                              ...articleChange!,
                              summary: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="p-grid p-align-center p-nogutter">
                    <div className="p-col-4">
                      <div>{Labels.LABEL_DISPLAY_ORDER}</div>
                    </div>
                    <div className="p-col-8 p-fluid margin-top-bottom-5">
                      <InputNumber
                        value={articleChange && articleChange.displayOrder}
                        onValueChange={(e: any) => {
                          setArticleChange({
                            ...articleChange!,
                            displayOrder: e.target.value,
                          });
                        }}
                        showButtons
                        buttonLayout="horizontal"
                        decrementButtonClassName="p-button-danger"
                        incrementButtonClassName="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        min={0}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                  {articleChange?.articleType === EnumArticleType.HTML && (
                    <div className="p-grid p-align-center p-nogutter">
                      <div className="p-col-4">
                        {(article?.summaryImage || articleOperation === EntityOperation.CREATE || articleOperation === EntityOperation.UPDATE) && <div>{Labels.LABEL_ARTICLE_SUMMARY_IMAGE}</div>}
                      </div>
                      <div className="p-col-8 p-fluid margin-top-bottom-5">
                        {(articleOperation === EntityOperation.READ || articleOperation === EntityOperation.DELETE) && article?.summaryImage && (
                          <img src={getImage(false, article?.summaryImage, null)} alt="#" className="image-position-left" />
                        )}
                        {articleOperation === EntityOperation.UPDATE && article?.summaryImage && <img src={getImage(false, article?.summaryImage, null)} alt="#" className="image-position" />}
                        {(articleOperation === EntityOperation.UPDATE || articleOperation === EntityOperation.CREATE) && (
                          <ImageUploader
                            className="uploader-position"
                            singleImage={true}
                            withLabel={false}
                            withPreview={true}
                            withIcon={false}
                            buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                            buttonClassName="upload-button"
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                            maxFileSize={65500}
                            fileSizeError={Labels.MESSAGES_FILE_SIZE_ERROR}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {/* !!!! ZAKOMENTARISANO ZATO STO ZA SADA OVO NECEMO NIGDE KORISTITI !!!!
                  <div className="p-grid p-align-center p-nogutter">
                    <div className="p-col-4">
                      {(article?.headerImage || articleOperation === EntityOperation.CREATE || articleOperation === EntityOperation.UPDATE) && <div>{Labels.LABEL_ARTICLE_HEADER_IMAGE}</div>}
                    </div>
                    <div className="p-col-8 p-fluid margin-top-bottom-5">
                      {(articleOperation === EntityOperation.READ || articleOperation === EntityOperation.DELETE) && article?.headerImage && (
                        <img src={getImage(false, article?.headerImage, null)} className="image-position-left" />
                      )}
                      {articleOperation === EntityOperation.UPDATE && article?.headerImage && <img src={getImage(false, article?.headerImage, null)} className="image-position" />}
                      {(articleOperation === EntityOperation.UPDATE || articleOperation === EntityOperation.CREATE) && (
                        <ImageUploader
                          className="uploader-position"
                          singleImage={true}
                          withLabel={false}
                          withPreview={true}
                          withIcon={false}
                          buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                          buttonClassName="upload-button"
                          onChange={onDropHeader}
                          imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                          maxFileSize={5242880}
                          fileSizeError={Labels.MESSAGES_FILE_SIZE_ERROR}
                        />
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel header={Labels.TAB_ARTICLE_CONTENT} disabled={!articleChange?.articleType}>
          {articleChange?.articleType === EnumArticleType.PLAIN_TEXT && (
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-12 p-fluid margin-top-bottom-5">
                  {articleChange && (article?.articleType === articleChange.articleType || selectedArticleType === articleChange.articleType) && (
                    <InputTextarea
                      rows={20}
                      cols={120}
                      disabled={isDisabled}
                      value={articleChange && articleChange.body}
                      onChange={(e: any) => {
                        setArticleChange({
                          ...articleChange!,
                          body: e.target.value,
                        });
                      }}
                    />
                  )}
                  {articleChange && article?.articleType !== articleChange.articleType && selectedArticleType !== articleChange.articleType && (
                    <InputTextarea
                      rows={20}
                      cols={120}
                      disabled={isDisabled}
                      onChange={(e: any) => {
                        setArticleChange({
                          ...articleChange!,
                          body: e.target.value,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.HTML && (
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-12 p-fluid margin-top-bottom-5">
                  {articleChange && (article?.articleType === articleChange.articleType || selectedArticleType === articleChange.articleType) && (
                    <Editor
                      headerTemplate={<div></div>}
                      modules={toolbar}
                      style={{ height: "320px" }}
                      value={articleChange && articleChange.body}
                      readOnly={isDisabled}
                      onTextChange={(e: any) => {
                        setArticleChange({
                          ...articleChange!,
                          body: e.htmlValue,
                        });
                      }}
                    />
                  )}
                  {articleChange && article?.articleType !== articleChange.articleType && selectedArticleType !== articleChange.articleType && (
                    <Editor
                      headerTemplate={<div></div>}
                      modules={toolbar}
                      style={{ height: "320px" }}
                      onTextChange={(e: any) => {
                        setArticleChange({
                          ...articleChange!,
                          body: e.htmlValue,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.URL && (
            <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center ">
                <div className="p-col-2">
                  <div>{Labels.LABEL_ARTICLE_URL}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  {articleChange && (article?.articleType === articleChange.articleType || selectedArticleType === articleChange.articleType) && (
                    <InputText
                      disabled={isDisabled}
                      value={articleChange && articleChange.body}
                      onChange={(e: any) => {
                        setArticleChange({
                          ...articleChange!,
                          body: e.target.value,
                        });
                      }}
                    />
                  )}
                  {articleChange && article?.articleType !== articleChange.articleType && selectedArticleType !== articleChange.articleType && (
                    <InputText
                      disabled={isDisabled}
                      onChange={(e: any) => {
                        setArticleChange({
                          ...articleChange!,
                          body: e.target.value,
                        });
                      }}
                    />
                  )}
                </div>
                <div className="p-col-2">
                  <Button
                    tooltip={Labels.BUTTON_PREVIEW}
                    tooltipOptions={{ position: "right" }}
                    icon="pi pi-eye"
                    className="p-button-success"
                    onClick={() => {
                      setMicrolinkUrl(articleChange?.body ? articleChange?.body : "");
                    }}
                  />
                </div>
                <div className="p-col-12">{microlinkUrl && microlinkUrl.length > 0 && microlinkUrl.startsWith("http") && <Microlink url={microlinkUrl.toString()} size="large" />}</div>
              </div>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.VIDEO && (
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12 tab-view">
              <div className="p-grid p-align-center ">
                <div className="p-col-12">
                  <span>{Labels.LABEL_ARTICLE_VIDEO}</span>
                  <span>
                    {articleChange && (article?.articleType === articleChange.articleType || selectedArticleType === articleChange.articleType) && (
                      <InputText
                        disabled={isDisabled}
                        value={articleChange && articleChange.body}
                        onChange={(e: any) => {
                          setArticleChange({
                            ...articleChange!,
                            body: e.target.value,
                          });
                        }}
                      />
                    )}
                    {articleChange && article?.articleType !== articleChange.articleType && selectedArticleType !== articleChange.articleType && (
                      <InputText
                        disabled={isDisabled}
                        onChange={(e: any) => {
                          setArticleChange({
                            ...articleChange!,
                            body: e.target.value,
                          });
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
        </TabPanel>

        <TabPanel
          header={Labels.TAB_ARTICLE_PREVIEW_SUMMARY}
          headerClassName={
            articleChange?.articleType === EnumArticleType.HTML || articleChange?.articleType === EnumArticleType.PLAIN_TEXT || articleChange?.articleType === EnumArticleType.URL ? "" : "display-none"
          }
        >
          {" "}
          {articleChange?.articleType === EnumArticleType.HTML && (
            <div>
              <Card className="card">
                <div className="p-grid p-justify-between">
                  <div className="p-col-6">
                    {articleChange?.summaryImage && <img src={getImage(false, articleChange.summaryImage, null)} alt="#" className="card-img-top" />}
                    {!articleChange?.summaryImage && <img src={Images.MSLOGO} alt="#" className="image-width" />}
                  </div>
                  <div className="p-col-6">
                    <p className="title-card">{articleChange?.title}</p>
                    {articleChange?.summary}
                  </div>
                </div>
              </Card>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.PLAIN_TEXT && (
            <div>
              <Card title={articleChange?.title} className="card">
                {articleChange?.summary}
              </Card>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.URL && (
            <div>
              <Card title={articleChange?.title} className="card">
                <a className="link-yellow" target="_blank" rel="noopener noreferrer" href={articleChange?.body}>
                  {articleChange?.body}
                </a>
              </Card>
            </div>
          )}
        </TabPanel>
        <TabPanel
          header={Labels.TAB_ARTICLE_PREVIEW_CONTENT}
          headerClassName={articleChange?.articleType === EnumArticleType.HTML || articleChange?.articleType === EnumArticleType.PLAIN_TEXT ? "" : "display-none"}
        >
          <div className="scrollable-content">
            <div>
              <div>
                <div id="article-ID" className="content-preview" style={{ overflow: "hidden" }}>
                  <p className="title">{articleChange?.title}</p>
                  <div className="statusContainer">
                    <span className="statusArticleCategory">
                      {articleChange?.articleArea?.articleCategory?.name}
                      {" • "}
                    </span>
                    <span className="statusArticleArea">
                      {articleChange?.articleArea?.name}
                      {" • "}
                    </span>
                    <span className="statusDateTime">{dateTimeBefore(articleChange?.insertTimestamp, new Date(now()))}</span>
                  </div>
                  {articleChange?.body && articleChange?.articleType === EnumArticleType.HTML && <div>{ReactHtmlParser(articleChange.body)}</div>}
                  {articleChange?.articleType === EnumArticleType.URL && <a href={articleChange.body}>{articleChange.body}</a>}
                  {articleChange?.articleType === EnumArticleType.PLAIN_TEXT && <div>{articleChange.body}</div>}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
