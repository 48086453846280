import React from "react";
import { InputText } from "primereact/inputtext";
import MsCafeListLogical, { MsCafeListLogicalType } from "./MsCafeListLogical";
import MembersList from "./MembersList";
import SkeletonAccordionList from "../skeleton/SkeletonAccordionList";

export default function MsCafeList() {
  const { members, membersLoading, searchMembers, toggleModerate, toggleForumAccess }: MsCafeListLogicalType = MsCafeListLogical();

  return (
    <div className="ms-cafe--wrapper">
      <div className="p-grid justify-content-center mb-4">
        <div className="p-lg-6 p-md-12 p-sm-12 p-col-10">
          <span className="p-input-icon-left ms-cafe--wrapper__search-members w-100">
            <InputText className="" onChange={(e) => searchMembers(e.target.value)} />
            <i className="pi pi-search p-icon-left" />
          </span>
        </div>
      </div>
      <div className="p-grid justify-content-center">
        <div className="p-lg-6 p-md-12 p-sm-12 p-col-10">
          {membersLoading ? <SkeletonAccordionList size={6} /> : <MembersList members={members} toggleModerate={toggleModerate} toggleForumAccess={toggleForumAccess} />}
        </div>
      </div>
    </div>
  );
}
